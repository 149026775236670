import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-toy-flip',
	templateUrl: './toy-flip.component.html',
	styleUrls: ['./toy-flip.component.scss'],
	host:{
		class:"w-100 d-flex justify-content-center align-items-center",
	},
	standalone: true,
	imports: [
		CommonModule
	]
})
export class ToyFlipComponent implements OnInit {

	@Input() imgUrl:string;

	@Input() seeMoreContent:string;

	@Input() durability:number = 0;

	@Input() toyTitle:string;

	@Input() toySubtitle:string;

	@Input() durabilityName:string;

	@Input() bgPositionY:string = '50px';

	public isFlipped:boolean = false;

	@Input() flipText:string = ''

	@Input() isSampleToy:boolean = false;

	constructor() { }

	ngOnInit(): void {
	}

	getDurabilityStatusArr(){
		return Array(this.durability).fill(0)
	}

	flip(){
		this.isFlipped = !this.isFlipped;
	}

}
