import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  public readonly API_ROOT: string = environment.API_ROOT;

  constructor(private http: HttpClient) { }

  getTracking() {
    return this.http.get<any>( this.API_ROOT + 'api/v1/tracking' );
  }

  gtm(event: string) {
    const fbp = this.getCookie('_fbp');
    const time = new Date();

    time.toUTCString();

    const url = 'https://data.bullymake.com/fb?event_name=fb_event'
      + '&fbp=' + fbp
      + '&user_agent=' + encodeURIComponent(localStorage.getItem('ua'))
      + '&ip=' + localStorage.getItem('uip')
      + '&event_url=' + encodeURIComponent(window.location.href)
      + '&event=' + encodeURIComponent(event)
      + '&event_time=' + Math.floor(time.getTime() / 1000);

    const tag = document.createElement('img');

    tag.src = url;
    tag.style.opacity = '0';
	tag.style.position = 'absolute';
	tag.style.width = '0';
	tag.style.height = '0';

    setTimeout(() => document.body.appendChild(tag), 100);
  }

  addEmailToKlaviyo(data) {
    return this.http.post<any>( this.API_ROOT + 'api/v1/bmke/ajax/', data );
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
}
