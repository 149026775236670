<!-- <div class='embedsocial-reviews' data-ref="c038affada4463cf7986dc7bf315826d2e9d01f1"></div><script>(function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/embedscript/ri.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialReviewsScript"));</script>
<script type="application/ld+json" class="reviews-schema" data-ref="c038affada4463cf7986dc7bf315826d2e9d01f1" > {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "BULLYMAKE-Farmers Branch",
    "aggregateRating":
    {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "200"
    }
} </script> -->

<section id="section1" class="section-item">
	<div class="container">
		<div class="section-content">
			<div class="row">
				<div class="col-md-5">
					<div class="column-content text-center">
						<h2 class="text-orange p-title h1">BULLYMAKE BOX</h2>
						<p class="p-desc mt-3"><span class="count bg-dark text-light">{{product.duration}}</span>
							{{product.period}} Subscription</p>

						<div *ngIf="!promotion">
							<span class="p-duration d-block mt-3">{{product.fullPrice}}</span>
							<span class="p-note d-block mt-2">{{product.note}}</span>

							<button class="btn btn-lg font-primary text-uppercase mt-4 px-2 mr-1"
								(click)="removeQty(cart);">-</button>
							<button class="btn btn-lg font-primary text-uppercase mt-4" (click)="updateCart(cart);">BUY
								{{cart.quantity}} NOW</button>
							<button class="btn btn-lg font-primary text-uppercase mt-4 px-2 ml-1" (click)="addQty(cart);">+</button>
						</div>

						<div *ngIf="promotion == 'a'">
							<span class="p-duration d-block mt-3">{{promotionDetails.fullPrice}}</span>
							<span class="p-note d-block mt-2" [innerHtml]="promotionDetails.note"></span>

							<button class="btn btn-lg font-primary text-uppercase mt-4 px-2 mr-1"
								(click)="removeQty(cart);">-</button>
							<button class="btn btn-lg font-primary text-uppercase mt-4" (click)="updateCart(cart);">BUY
								{{cart.quantity}} NOW</button>
							<button class="btn btn-lg font-primary text-uppercase mt-4 px-2 ml-1" (click)="addQty(cart);">+</button>
						</div>

						<div *ngIf="promotion == 'b'">
							<span class="p-duration d-block mt-3"><del>{{product.fullPrice}}</del></span>
							<span class="p-duration d-block mt-3">{{promotionDetails.fullPrice}}</span>
							<span class="p-note d-block mt-2" [innerHtml]="promotionDetails.note"></span>

							<button class="btn btn-lg font-primary text-uppercase mt-4" (click)="updateCart(cart);">SUBSCRIBE
								NOW</button>
						</div>

						<div *ngIf="promotion == 'c'">
							<span class="p-duration d-block mt-3"><del>{{product.fullPrice}}</del></span>
							<span class="p-duration d-block mt-3">{{promotionDetails.fullPrice}}</span>
							<span class="p-note d-block mt-2" [innerHtml]="promotionDetails.note"></span>

							<button class="btn btn-lg font-primary text-uppercase mt-4 px-2 mr-1"
								(click)="removeQty(cart);">-</button>
							<button class="btn btn-lg font-primary text-uppercase mt-4" (click)="updateCart(cart);">BUY
								{{cart.quantity}} NOW</button>
							<button class="btn btn-lg font-primary text-uppercase mt-4 px-2 ml-1" (click)="addQty(cart);">+</button>
						</div>

						<div *ngIf="promotion == 'fb20'">
							<span class="p-duration d-block mt-3"><del>{{product.fullPrice}}</del></span>
							<span class="p-duration d-block mt-3 text-green">{{promotionDetails.fullPrice}}</span>
							<span class="p-note d-block mt-2" [innerHtml]="promotionDetails.note"></span>

							<button class="btn btn-lg font-primary text-uppercase mt-4" (click)="updateCart(cart);">SUBSCRIBE
								NOW</button>
						</div>

					</div><!-- END .column-content -->
				</div><!-- END .col-sm-5 -->

				<div class="col-md-7">
					<div class="column-content mt-5 mt-md-0">
						<carousel class="type2">
							<slide *ngFor="let slide of product.slides">
								<img [src]="slide.url" alt="" style="display: block; width: 100%;">
							</slide>
						</carousel>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-7 -->
			</div><!-- END .row -->
		</div><!-- END .section-content -->
	</div><!-- END.container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item bg-light">
	<div class="container">
		<div class="section-content">
			<div class="row">
				<div class="col-md-4 col-sm-6">
					<div class="column-content shadow-sm mt-4 mt-md-0">
						<img class="img-fluid" src="assets/img/single-product/col-item1.jpg" alt="">
						<p class="description m-2">We get it, your dog is tough on toys. You've come to the right place - we here
							at <span class="text-orange">BULLYMAKE</span> are experts at handling this situation with top notch
							products.</p>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-4 -->

				<div class="col-md-4 col-sm-6">
					<div class="column-content shadow-sm mt-4 mt-md-0">
						<img class="img-fluid" src="assets/img/single-product/col-item2.jpg" alt="">
						<p class="description m-2">Your dog will love our high-quality, tough toys & treats - all backed by our
							<span class="text-orange">BULLYMAKE</span> guarantee. No toy destroyed or leaves you unsatisfied, or we
							ship you a different item for free!
						</p>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-4 -->

				<div class="col-md-4 col-sm-12">
					<div class="column-content shadow-sm mt-4 mt-md-0">
						<img class="img-fluid" src="assets/img/single-product/col-item3.jpg" alt="">
						<p class="description m-2">Enjoy the ease and simplicity of allowing us to ship a box of DURABLE toys and
							treats right to your door. No more wasted time at the store.</p>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-4 -->

			</div><!-- END .row -->
		</div><!-- END .section-content -->
	</div><!-- END .container -->
</section><!-- END #section2 -->

<div id="bm-guarantee" class="section-item text-center bg-orange text-md-left">
	<div class="container">
		<div class="section-content">
			<div class="row align-items-center">
				<div class="col-12 col-sm-2">
					<img class="img-fluid guarantee-icon mb-4 mb-sm-0 shadow" src="assets/img/home/guarantee-light.png" alt="">
				</div><!-- END .col-sm-3 -->

				<div class="col-12 col-sm-10">
					<div class="column-content text-light">
						<h3 class="h1">THE BULLYMAKE GUARANTEE</h3>
						<p class="lead">If you or your dog aren't 100% satisfied with an item - or somehow your dog gets the best
							of one of our toys - we'll replace it with a different item for free.</p>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-9 -->
			</div>
		</div><!-- END .section-content -->
	</div><!-- END .container -->
</div><!-- END #bm-guarantee -->

<section id="toysSlider" class="section-item text-center bg-light py-5">
	<div class="container">
		<div class="section-title-content container-sm">
			<span class="text-orange font-primary text-uppercase h5">Made in the USA <img style="width:40px;"
					class="icon-flag ml-4" src="assets/img/home/united-states-of-america.svg" alt=""></span>
			<h2 class="section-title text-uppercase mb-3 mt-2"><span class="text-teal">SUPER TOUGH</span> TOYS + TREATS</h2>
			<p class="subtitle lead">Our specialty nylon, rubber, and ballistic toys as well as our treats are made in the
				USA. Our toys are tested by TOUGH chewers and built to last.</p>
		</div><!-- END .section-title-content -->

		<br>

		<div id="toysSliderCarousel" class="carousel-wrapper style-1 mt-3">
			<carousel [showIndicators]="false" class="d-none d-lg-block">
				<slide class="px-5">
					<div class="row">
						<div class="col-3">
							<h4 class="text-orange text-uppercase">Nylon</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_01@2x.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<h4 class="text-orange text-uppercase">Rubber</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_02@2x.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<h4 class="text-orange text-uppercase">Ballistic</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_03@2x.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<h4 class="text-orange text-uppercase">Custom Treats</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_04@2x.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
					</div><!-- END .row -->
				</slide>
			</carousel>

			<carousel [showIndicators]="false" class="d-none d-md-block d-lg-none">
				<slide class="px-5">
					<div class="row">
						<div class="col-6">
							<h4 class="text-orange text-uppercase">Nylon</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_01@2x.jpg" alt="blockquote">
						</div><!-- END .col-3 -->

						<div class="col-6">
							<h4 class="text-orange text-uppercase">Rubber</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_02@2x.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
					</div><!-- END .row -->
				</slide>

				<slide class="px-5">
					<div class="row">
						<div class="col-6">
							<h4 class="text-orange text-uppercase">Ballistic</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_03@2x.jpg" alt="blockquote">
						</div><!-- END .col-6 -->

						<div class="col-6">
							<h4 class="text-orange text-uppercase">Custom Treats</h4>
							<img class="d-inline-block shadow shadow-sm img-fluid mt-3 mb-5"
								src="assets/img/home/Toy_Banner_04@2x.jpg" alt="blockquote">
						</div><!-- END .col-6 -->
					</div><!-- END .row -->
				</slide>
			</carousel>

			<div class="mobile-carousel mb-5 d-md-none">
				<div class=" flex-nowrap px-2">
					<img class="slide-item d-inline-block shadow shadow-sm img-fluid mt-3 mb-3"
						src="assets/img/home/Toy_Banner_01@2x.jpg" alt="blockquote">
					<img class="slide-item d-inline-block shadow shadow-sm img-fluid mt-3 mb-3"
						src="assets/img/home/Toy_Banner_02@2x.jpg" alt="blockquote">
					<img class="slide-item d-inline-block shadow shadow-sm img-fluid mt-3 mb-3"
						src="assets/img/home/Toy_Banner_03@2x.jpg" alt="blockquote">
					<img class="slide-item d-inline-block shadow shadow-sm img-fluid mt-3 mb-3"
						src="assets/img/home/Toy_Banner_04@2x.jpg" alt="blockquote">
				</div>
			</div><!-- END .mobile-carousel -->
		</div><!-- END .carousel-wrapper -->
	</div><!-- END .container -->

	<section id="cta" class="container section-item bg-green bb pt-md-5 pt-4 pb-4">
		<div class="section-content">
			<ul class="list-inline text-light d-lg-flex justify-content-between mb-0">
				<li class="list-inline-item h4 pt-2">Rigorously Tested</li>
				<li class="list-inline-item h4 pt-2">Non-Toxic</li>
				<li class="list-inline-item h4 pt-2">Extremely Durable</li>
				<li class="list-inline-item h4 pt-2">FDA Approved Materials</li>
				<li class="list-inline-item h4 d-none d-md-block mt-4 mt-lg-0"><a (click)="updateCart(cart);"
						class="btn btn-filled btn-lg btn-orange">Buy Now</a></li>
			</ul>

			<a (click)="updateCart(cart);" class="d-md-none btn btn-filled btn-lg btn-orange text-light mt-4">Buy Now</a>
		</div><!-- END .section-content -->
	</section><!-- END #cta -->
</section><!-- END #toysSlider -->

<section id="section5" class="section-item bg-orange text-light text-center">
	<div class="container">
		<h2 class="section-title text-uppercase">Why buy from Bullymake?</h2>
		<div class="section-content">
			<div class="row">
				<div class="col-sm-6 col-md-4">
					<div class="column-content">
						<img class="item-icon img-fluid" src="assets/img/home/img1_sec4.png" alt="">
						<p class="desc">We manufacture and ship the tough stuff. If your dog is an extreme chewer, tugger,
							fetcher, or player - we got you covered.</p>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-4 -->

				<div class="col-sm-6 col-md-4">
					<div class="column-content">
						<img class="item-icon img-fluid" src="assets/img/home/img2_sec4.png" alt="">
						<p class="desc">We offer an industry leading durability and satisfaction guarantee. Nothing destroyed or
							leaves your dog unsatisfied, or we replace it for free.</p>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-4 -->

				<div class="col-sm-12 col-md-4">
					<div class="column-content">
						<img class="item-icon img-fluid" src="assets/img/home/img3_sec4.png" alt="">
						<p class="desc">Our specialty Nylon, Rubber, and Ballistic materials are made in the USA. Our delicious
							treats are made in the USA.</p>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-4 -->
			</div><!-- END .row -->

			<!-- <div class="subsection mt-5">
          <h5 class="subsection-title text-uppercase position-relative"><span class="position-relative bg-orange">As Seen on!</span></h5>
          <img class="featured-on img-fluid mt-5" src="assets/img/home/as-seen-on.png" alt="">
        </div> -->
			<!-- END .subsection -->
		</div><!-- END .section-content -->
	</div><!-- END .container -->
</section><!-- END #section5 -->

<section id="section3" class="section-item text-center">
	<div class="container">
		<h2 class="section-title text-uppercase">Here’s how it works.</h2>
		<div class="section-content">
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="step-thumb img-fluid" src="assets/img/home/step3.png" alt="Step 3">
						<h6 class="step-title text-orange">PICK YOUR PLAN. WE OFFER DISCOUNTS UP TO 20% FOR LONGER-TERM.</h6>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-3 -->

				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="step-thumb img-fluid" src="assets/img/home/step1.png" alt="Step 1">
						<h6 class="step-title text-orange">TELL US ABOUT YOUR PET (WEIGHT, ALLERGIES, AND EVEN TOY PREFERENCES).
						</h6>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-3 -->

				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="step-thumb img-fluid" src="assets/img/home/step2.png" alt="Step 2">
						<h6 class="step-title text-orange"><span class="text-dark">New!</span> TELL US WHAT SHOULD BE IN YOUR BOX
							– OR LET US DECIDE.</h6>
					</div>
				</div>


				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="step-thumb img-fluid" src="assets/img/home/step4.png" alt="Step 4">
						<h6 class="step-title text-orange">WE’LL SHIP YOU A BULLYMAKE BOX ON THE NEXT BUSINESS DAY – AND EVERY 30
							DAYS THEREAFTER.</h6>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-3 -->
			</div><!-- END .row -->
		</div><!-- END .section-content -->
	</div><!-- END .container -->
</section><!-- END #section3 -->


<div id="section7" class="section-item text-center bg-light">
	<div class="container">
		<h2 class="section-title text-uppercase text-orange">Compare plans</h2>
		<div class="section-content">
			<div class="row">
				<div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="selected-box" *ngIf="cart.product_id == 3276883"
							src="assets/img/single-product/animal-paw-print.png" alt="">
						<img class="img-fluid item-thumb" src="assets/img/home/1-box.png" alt="">
						<div class="item-details mt-3">
							<h4 class="item-title text-uppercase"><span class="text-orange">1 month:</span> $45/mo</h4>
							<!-- <p class="item-desc">Billed Monthly</p> -->
						</div><!-- END .item-details -->
						<a class="btn btn-sm font-primary btn-teal" [routerLink]="['/product/1-month-subscription']">SELECT</a>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-3 -->

				<div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="selected-box" *ngIf="cart.product_id == 3276878"
							src="assets/img/single-product/animal-paw-print.png" alt="">
						<img class="img-fluid item-thumb" src="assets/img/home/3-box.png" alt="">
						<div class="item-details mt-3">
							<h4 class="item-title text-uppercase"><span class="text-orange">3 months:</span> $36/mo</h4>
							<p class="item-desc">Save 22%</p>
							<small class="item-badge text-orange">* Most Popular!</small>
						</div><!-- END .item-details -->
						<a class="btn btn-sm font-primary btn-teal" [routerLink]="['/product/3-month-subscription']">SELECT</a>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-3 -->

				<div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="selected-box" *ngIf="cart.product_id == 3276880"
							src="assets/img/single-product/animal-paw-print.png" alt="">
						<img class="img-fluid item-thumb" src="assets/img/home/6-box.png" alt="">
						<div class="item-details mt-3">
							<h4 class="item-title text-uppercase"><span class="text-orange">6 months:</span> $34/mo</h4>
							<p class="item-desc">Save 28%</p>
						</div><!-- END .item-details -->
						<a class="btn btn-sm font-primary btn-teal" [routerLink]="['/product/6-month-subscription']">SELECT</a>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-3 -->

				<div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
					<div class="column-content">
						<img class="selected-box" *ngIf="cart.product_id == 3276881"
							src="assets/img/single-product/animal-paw-print.png" alt="">
						<img class="img-fluid item-thumb" src="assets/img/home/12-box.png" alt="">
						<div class="item-details mt-3">
							<h4 class="item-title text-uppercase"><span class="text-orange">Annual:</span> $31/mo</h4>
							<p class="item-desc">Save 34%</p>
							<small class="item-badge text-orange">* Best value!</small>
						</div><!-- END .item-details -->
						<a class="btn btn-sm font-primary btn-teal" [routerLink]="['/product/1-year-subscription']">SELECT</a>
					</div><!-- END .column-content -->
				</div><!-- END .col-sm-3 -->
			</div><!-- END .row -->
		</div><!-- END .section-content -->
	</div><!-- END .container -->
</div><!-- END #section7 -->

<div id="reviews-from-google" class="section-item bg-white pb-0">

	<div class="container">
		<h2 class="section-title text-center text-uppercase mb-5 mt-2">Reviews from Google</h2>

		<div id="instagramCarousel" class="mt-5 carousel-wrapper style-1">
			<carousel [singleSlideOffset]="true" [interval]="3000" [showIndicators]="false" [noWrap]="true" [itemsPerSlide]="3"
				class="w-auto">
				<slide *ngFor="let review of reviews; let i = index" class="mx-2" [ngStyle]="{'minWidth':'350px'}">
					<app-google-review [review]="review" [ngClass]="{'w-100':true}"></app-google-review>
				</slide>
			</carousel>
		</div>
		<!--END #googleReviewsCarousel -->
	</div>

	<section id="section6" class="section-item bg-light">
		<div class="container">
			<h2 class="section-title text-center text-uppercase">YOUR QUESTIONS - ANSWERED!</h2>
			<div class="section-content">
				<div class="row">
					<div class="col-sm-4">
						<div class="column-content">
							<accordion class="type-2" [closeOthers]="true">
								<accordion-group heading="What's in the box?">
									<p>Depending on your order, you will receive 2-3 toys and 3 full bags of grain free, USA made
										treats.
										We also have a "toys only" options where you will receive 4-5 toys instead of the treats.</p>
								</accordion-group>
								<accordion-group heading="When does it ship?">
									<p>We ship every single order the next Business day. All subsequent boxes are shipped 30 days after
										your first shipping date.</p>
								</accordion-group>
								<accordion-group heading="Where are your products made?">
									<p>Our toys and treats are all made in the USA except for our rope product (which is made in China).
										Rubber, nylon, and ballistic are all made in the USA. All of our treats are made in the USA.</p>
								</accordion-group>
								<accordion-group heading="What if my dog grows during the subscription?">
									<p>No problem! You can update your dog’s weight as they grow at any time in the “my account” area!
									</p>
								</accordion-group>
							</accordion>
						</div><!-- END .column-content -->
					</div><!-- END .col-sm-4 -->

					<div class="col-sm-4">
						<div class="column-content">
							<accordion class="type-2" [closeOthers]="true">
								<accordion-group heading="Is there a guarantee if something doesn’t work?">
									<p>You bet! We have an industry leading BULLYMAKE guarantee. If your dog destroys or is unsatisfied
										with any of our items, we replace them with completely different items for free.</p>
								</accordion-group>
								<accordion-group heading="Is a subscription required?">
									<p>A subscription is not required! All products are subscriptions by default but you may stop
										shipments at any time.</p>
								</accordion-group>
								<accordion-group heading="What are my customization options?">
									<p>Currently you may select from nylon, rubber, ballistic, or rope materials for your toys. This is
										done post-purchase. You can also choose from several allergy preferences.</p>
								</accordion-group>
								<accordion-group heading="Do you ever send Rawhides?">
									<p>NEVER! Too dangerous.</p>
								</accordion-group>
							</accordion>
						</div><!-- END .column-content -->
					</div><!-- END .col-sm-4 -->

					<div class="col-sm-4">
						<div class="column-content">
							<accordion class="type-2" [closeOthers]="true">
								<accordion-group heading="What if my dog has severe allergies?">
									<p>No problem! We have a "toys only" option where we replace all treats in the box with 2 additional
										toys, for a total of 4 toys.</p>
								</accordion-group>
								<accordion-group heading="How does it work / how do I cancel?">
									<p>Pick any of our plans (1, 3, 6, or 12 months). We'll ship your first box the next business day.
										Each plan automatically renews - and you can cancel anytime for the monthly one while the 3, 6 and 12 months will be under commitment period.</p>
								</accordion-group>
								<accordion-group heading="Can Australia & Canada receive treats?">
									<p>At this time, Canada and Australia cannot receive shipments with treats. We are working with both
										borders. Orders will automatically be switched to our "toys only" option which includes 4-5 toys
										per
										shipment!</p>
								</accordion-group>
								<!-- <accordion-group heading="What toy customizations can I receive?">
                  <p>We'll let you select from Nylon, Rubber, Ballistic, or Rope materials. Please click "I'll do it" at the bottom of the page to get started.</p>
                </accordion-group> -->
								<accordion-group heading="Who delivers your boxes?">
									<p>We make the final delivery via USPS, Canada post or Australian Post depending on your country!
									</p>
								</accordion-group>
							</accordion>
						</div><!-- END .column-content -->
					</div><!-- END .col-sm-4 -->
				</div><!-- END .row -->
			</div><!-- END .section-content -->
		</div><!-- END .container -->
	</section><!-- END #section6 -->


	<section id="cu" class="section-item pt-0">
		<div class="container position-relative pt-3 pt-sm-4">
			<div class="section-header">
				<img class="position-absolute top-icon" src="./assets/brand/favicon.png" alt="Bullymake" height="100">
				<h2 class="text-uppercase fw-semibold">Chewers Unite</h2>
				<p>Show us your happy chewer <span class="text-primary">#bullymake</span></p>
			</div><!-- END .section-header -->
			<!-- <img src="./assets/img/instagram-feed.png" class="img-fluid mt-5 w-100" alt=""> -->

			<div id="instagramCarousel" class="mt-5 carousel-wrapper style-1">
				<carousel [showIndicators]="false" class="d-none d-md-block">
					<slide>
						<div class="row">
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-1.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-2.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-3.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-4.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
						</div><!-- END .row -->
					</slide>

					<slide>
						<div class="row">
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-5.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-6.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-7.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
							<div class="col-3">
								<img class="d-inline-block shadow img-fluid"
									src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-1.jpg" alt="blockquote">
							</div><!-- END .col-3 -->
						</div><!-- END .row -->
					</slide>
				</carousel>

				<carousel [showIndicators]="false" class="d-md-none d-block">

					<slide>
						<img class="d-block w-100 shadow img-fluid"
							src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-1.jpg" alt="blockquote">
					</slide>

					<slide>
						<img class="d-block w-100 shadow img-fluid"
							src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-2.jpg" alt="blockquote">
					</slide>

					<slide>
						<img class="d-block w-100 shadow img-fluid"
							src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-3.jpg" alt="blockquote">
					</slide>

					<slide>
						<img class="d-block w-100 shadow img-fluid"
							src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-4.jpg" alt="blockquote">
					</slide>

					<slide>
						<img class="d-block w-100 shadow img-fluid"
							src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-5.jpg" alt="blockquote">
					</slide>

					<slide>
						<img class="d-block w-100 shadow img-fluid"
							src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-6.jpg" alt="blockquote">
					</slide>
				</carousel>
			</div><!-- END #testimonialCarousel -->

		</div><!-- END .container -->
	</section><!-- END .section-item -->



	<footer class="product-footer" #productFooter [hidden]="promotion">
		<div class="container">
			<div class="section-content">
				<div class="row">
					<div class="col-sm-6 d-flex flex-column justify-content-center">
						<div class="column-content">
							<!-- <h3 class="text-light text-uppercase text-md-right text-center">Who do you want to curate your box?</h3> -->
							<h3 class="text-light text-uppercase text-md-right text-center">Ready for your first box?</h3>
						</div><!-- END .column-content -->
					</div><!-- END .col-sm-6 -->

					<div class="col-sm-6">
						<div class="column-content">
							<div class="row">
								<div class="col-12 text-md-left text-center">
									<button class="btn w-100 font-primary" (click)="updateCart(cart);" style="max-width: 250px;">BUY
										NOW</button>
								</div>

								<!-- <div class="col-6 text-md-left text-center">
                  <button class="btn w-100 d-block font-primary" [routerLink]="'customize/'">I'LL DO IT</button>
                </div> -->
								<!-- END .col-6 -->

								<!-- <div class="col-6">
                  <button class="btn w-100 font-primary" (click)="updateCart(cart);">BULLYMAKE</button>
                  <button class="btn w-100 font-primary text-uppercase" (click)="updateCart(cart);">Yes! let's continue</button>
                </div> -->
								<!-- END .col-6 -->
							</div><!-- END .row -->
						</div><!-- END .column-content -->
					</div><!-- END .col-sm-6 -->
				</div><!-- END .row -->
			</div><!-- END .section-content -->
		</div><!-- END .container -->
	</footer><!-- END .product-footer -->
</div>

<!-- Use of this pixel is subject to the Amazon ad specs and policies at http://www.amazon.com/b/?&node=7253015011 -->
<script type='text/javascript'>
	var _pix = document.getElementById('_pix_id_a7de861d-3d05-cb11-9b7a-f270ab8a3a42');
	if (!_pix) {
		var protocol = '//';
		var a = Math.random() * 1000000000000000000;
		_pix = document.createElement('iframe');
		_pix.style.display = 'none';
		_pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Da7de861d-3d05-cb11-9b7a-f270ab8a3a42%26type%3D43%26m%3D1&ex-fch=416613&ex-src=https://bullymake.com/&ex-hargs=v%3D1.0%3Bc%3D3652507800501%3Bp%3DA7DE861D-3D05-CB11-9B7A-F270AB8A3A42' + '&cb=' + a);
		_pix.setAttribute('id', '_pix_id_a7de861d-3d05-cb11-9b7a-f270ab8a3a42');
		document.body.appendChild(_pix);
	}
</script>
<noscript> <img height='1' width='1' border='0' alt=''
		src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Da7de861d-3d05-cb11-9b7a-f270ab8a3a42%26type%3D43%26m%3D1&ex-fch=416613&ex-src=https://bullymake.com/&ex-hargs=v%3D1.0%3Bc%3D3652507800501%3Bp%3DA7DE861D-3D05-CB11-9B7A-F270AB8A3A42' /></noscript>
