import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ToyFlipComponent } from './toy-flip/toy-flip.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'home-version-b',
	templateUrl: './version-b.component.html',
	styleUrls: ['./version-b.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		VideoDialogComponent,
		ToyFlipComponent,
		CarouselModule,
	]
})
export class VersionBComponent implements OnInit {

	public isPlayed: boolean = false;

	@ViewChild('video') video: any;

	public onClickPoster() {

		// this.isPlayed = true

		// this.video.nativeElement.controls = true;



		// add controls


	}

	public isPlaying: boolean = false;

	public isMuted: boolean = false;

	public play() {
		this.video.nativeElement.play();

		this.isPlaying = true;
	}

	public pause() {
		this.video.nativeElement.pause();

		this.isPlaying = false;
	}

	public mute() {

		this.video.nativeElement.muted = true;

		this.isMuted = true;
	}

	public unmute() {
		this.video.nativeElement.muted = false;

		this.isMuted = false;
	}




	constructor(private dialog: MatDialog, private domSanitizer: DomSanitizer) { }

	public videoCards: any = [
		{
			title: 'Colonel vs Our',
			subTitle: 'Rubber Skull',
			thumbnail: '/assets/img/current-box-landing-light-v2/rubber-skull.jpg',
			videoLink: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/uQ9wd50xPE8?rel=0')
		},
		{
			title: 'Blow Torch Vs Our ',
			subTitle: 'Rubber Jackolyn',
			thumbnail: '/assets/img/current-box-landing-light-v2/rubber-jackolyn.jpg',
			videoLink: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/pYeXCT_SLqI?rel=0')
		},
		{
			title: 'Rocky vs Our',
			subTitle: 'Nylon Crab',
			thumbnail: '/assets/img/current-box-landing-light-v2/nylon-crab.jpg',
			videoLink: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/yPuvhaaa85I?rel=0')
		},
		{
			title: 'Scissors Vs Our',
			subTitle: 'Nylon Turkey Leg',
			thumbnail: '/assets/img/current-box-landing-light-v2/nylon-turkey-leg.jpg',
			videoLink: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/j5-Ujn5Q_IA?rel=0')
		},
		{
			title: 'Hammer vs Our',
			subTitle: 'Nylon Trident',
			thumbnail: '/assets/img/current-box-landing-light-v2/nylon-trident.jpg',
			videoLink: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/KA_BmTW6Vag?rel=0')
		},
	]

	ngOnInit(): void {

		setTimeout(() => {
			this.play();
			this.mute();
		}, 1000)
		// this.onClickPoster()
	}

	scroll(el: HTMLElement, size: number) {

		el.scrollLeft = el.scrollLeft + size;
	}

	viewVideo(url: string) {

		this.dialog.open(VideoDialogComponent, {
			data: {
				url: url,
			},
			id: 'viewVideoDialog',
			width: '800px'
		})
	}

	public trustUrl(url: string) {

		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
