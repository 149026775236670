<section id="whyChooseBullymake" class="section-item bg-light text-center">
    <h2 class="position-absolute"><span
            class="text-light px-5 py-3 bb bg-primary d-inline-block text-uppercase fw-light">Why Choose
            Bullymake</span></h2>
    <div class="container mt-5 pt-3">
        <div class="row">
            <div class="col-sm-4">
                <div class="column-content">
                    <img class="img-fluid" src="./assets/img/extreme-chewers.png" alt="">
                    <h5 class="text-uppercase mt-4 fw-semibold">For Extreme Chewers</h5>
                    <p class="mt-3">We manufacture and ship tough dog beds. If your dog is an extreme chewer, this is the bed for you.</p>
                </div><!-- END .column-content -->
            </div><!-- END .col-sm-4 -->

            <div class="col-sm-4">
                <div class="column-content">
                    <img class="img-fluid" src="./assets/img/satisfaction-guarantee.png" alt="">
                    <h5 class="text-uppercase mt-4 fw-semibold">Satisfaction Guarantee</h5>
                    <p class="mt-3">Industry leading durability and satisfaction guarantee. Nothing destroyed or leaves your dog unsatisfied.</p>
                </div><!-- END .column-content -->
            </div><!-- END .col-sm-4 -->

            <div class="col-sm-4">
                <div class="column-content">
                    <img class="img-fluid" src="./assets/img/made-in-usa.png" alt="">
                    <h5 class="text-uppercase mt-4 fw-semibold">Made in the USA</h5>
                    <p class="mt-3">Our specialty Ballistic beds are made in the USA. Just like you like it.</p>
                </div><!-- END .column-content -->
            </div><!-- END .col-sm-4 -->
        </div><!-- END .row -->
    </div><!-- END .container -->
</section><!-- END #whyChooseBullymake -->