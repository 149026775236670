import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'bullymake-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit {

	/**
	 * Getting size as a input
	 */
	@Input() size: 'sm' | 'md' | 'lg' = 'md';

	/**
	 * Getting background color as a input
	 */
	@Input() color: 'primary' | 'secondary' = 'primary';

	/**
	 * Getting isLoading flag as a input
	 */
	@Input() isLoading: boolean = false;

	/**
	 * Getting Button type as a input
	 */
	@Input() type: 'submit' | 'reset' | 'button' = 'button';

	constructor() { }

	ngOnInit(): void {
	}
}
