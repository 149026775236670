<mat-dialog-content class="text-center">

  <div [hidden]="this.step!==1">
    <h2 class="mt-3 mb-3 text-uppercase text-orange">
      Want an extra $10 off?
    </h2>
    <p>Enter your email below, and we'll send you a coupon code</p>

    <form [formGroup]="subscribeForm">
      <div class="form-group">
        <mat-form-field class="w-100">
          <mat-label>Your Email</mat-label>
          <input matInput placeholder="Your Email" formControlName="email" autocomplete="email">
          <mat-error *ngIf="this.subscribeForm.controls.email.hasError('email') && !this.subscribeForm.controls.email.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="this.subscribeForm.controls.email.hasError('required')">Email is <strong>required</strong></mat-error>
        </mat-form-field>
      </div><!-- END .form-group -->
    </form>
  </div>

  <div [hidden]="this.step!==2">
    <h2 class="mt-3 mb-3 text-uppercase text-orange">
      Thank You!
    </h2>
    <p>Please check your inbox for your coupon code</p>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="text-center w-100 mb-2" [hidden]="this.step!==1">
    <button class="btn btn-lg btn-orange m-1" (click)="submitEmail()" [disabled]="!this.subscribeForm.valid || this.submitting">SUBMIT</button>
    <button class="btn btn-lg btn-bordered m-1" (click)="close()">NO THANKS</button>
  </div>
</mat-dialog-actions>
