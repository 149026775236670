import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BullymakeBlogService {

	public readonly API_ROOT: string = environment.API_ROOT;

	public constructor(private http: HttpClient) { }

	public getPosts(page: number) {

		return this.http.get<any>(this.API_ROOT + 'wp/v2/posts?page=' + page + '&_embed', { observe: 'response' });
	}

	public getPost(slug: string) {
		return this.http.get<any>(this.API_ROOT + 'wp/v2/post/' + slug + '?_embed');
	}
}
