<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase text-orange mb-3 text-center">GET YOUR DURABLE DOG TOYS AND TREATS SHIPPED RIGHT TO YOU DURING OUR "NEW NORMAL."</h3>

    <div class="section-content mt-5">
      <img class="img-fluid rounded mb-4 shadow" src="/assets/img/dogs-multi.png" alt="">
      <p class="lead mt-5">At BULLYMAKE we understand the "new normal."  Social distancing, mask requirements, and general quarantine.  Thanks, Corona! But why go out shopping for your dogs tough chew toys and treats?  You really shouldn't - and you don't need to.</p>
      <p class="lead">BULLYMAKE has been around since 2014 with a special mission: keep power chewing dogs (and their parents) happy and satisfied. The way BULLYMAKE does this is by designing and manufacturing unique, highly durable toys every month catered specifically to your power chewer right here in the USA, and shipped straight to your door.</p>
      <p class="lead">BULLYMAKE specializes in Nylon, Ballistic, and Rubber toys that cater to chewing, tugging, fetching, and several other play tendencies. You also have the option of selecting a rope material. Take a quick look at the video for more about the toy options:</p>
      <div class="text-center mt-5">
        <iframe class="bullymake-video" width="560" height="315" src="https://www.youtube.com/embed/ba--WxJYe9o?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section3" class="section-item text-center bg-light">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">LIMITED TIME - 40% Off Your First Box! <br> Join TODAY and Take Advantage While This Offer Lasts.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion-b']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section2" class="section-item">
  <div class="container h-100">
    <div class="section-content">
      <h3 class="text-uppercase text-orange text-center">THE GENIUS OF BULLYMAKE IS THAT ALL TOYS YOU RECEIVE ARE EXTREMELY TOUGH, CUSTOMIZED FOR YOUR PET, AND COME WITH A DURABILITY AND SATISFACTION GUARANTEE.</h3>
      <p class="lead mt-5">Absolutely nothing goes destroyed or leaves you unsatisfied or we replace it with a different item for FREE.  Simple.</p>
      <p class="lead">Each BULLYMAKE box is filled with 2-3 amazing, highly durable toys created specifically for your dog. You can even customize the materials of the toys you receive on a monthly basis. BULLYMAKE also sends 3-4 treats in every box that are all natural, healthy, and tie into the monthly theme. You can even choose a “Toys Only” option in case your dog has severe allergies.</p>
      <img class="img-fluid rounded shadow mt-5 mb-4" src="/assets/img/landing-dog.jpg" alt="">
      <p class="lead">And if that wasn’t enough, BULLYMAKE manufactures their specialty Nylon, Rubber, and Ballistic products in the USA. All items are backed with a durability and satisfaction guarantee - nothing goes destroyed or it is replaced for free!</p>
      <p class="lead">Take advantage of our 40% off special offer to get your first box for just $23. You won't find a better deal for your dog.</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section2 -->

<section id="section3" class="section-item text-center bg-light">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">LIMITED TIME - 40% Off Your First Box! <br> Join TODAY and Take Advantage While This Offer Lasts.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion-b']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section4" class="section-item pb-0">
  <div class="container">
    <h2 class="section-title text-dark text-uppercase mb-3 text-center">And check out all of their awesome reviews across the web:</h2>
    <div class="section-content text-center mt-5">
      <img class="bordered shadow img-fluid" src="assets/img/greviews.jpg" alt="">
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->