import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RemovalService {

  public readonly API_ROOT: string = environment.API_ROOT;

  constructor(private http: HttpClient) { }

  process(userEmail) {
    return this.http.get<any>( this.API_ROOT + 'api/v1/removal?action=process&e=' + userEmail, {observe: 'response'} );
  }

  confirm(userEmail) {
    return this.http.get<any>( this.API_ROOT + 'api/v1/removal?action=confirm&e=' + userEmail, {observe: 'response'} );
  }
}
