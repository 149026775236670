<section id="primary" class="section-item">
	<div class="container">
		<h2 class="section-title text-dark text-uppercase mb-5 text-center">Bullymake Blog</h2>

		<main id="main" class="site-main">
			<div class="icon-holder text-center mb-5" *ngIf="!loaded">
				<img class="article-icon" src="/assets/img/article.png" alt="">
			</div><!-- END .icon-holder -->

			<article *ngFor="let item of posts" class="mb-5 post-item">
				<header class="entry-header pb-3 mb-3">
					<img class="featured-image" *ngIf="item._embedded['wp:featuredmedia']" src="{{item._embedded['wp:featuredmedia'][0].source_url}}" alt="">
					<h2 class="entry-title text-uppercase mt-3"><a class="text-dark" [routerLink]="['/blog/' + item.slug]" rel="bookmark" [innerHTML]="sanitize(item.title.rendered)"></a></h2>
					<div class="entry-meta">
						<span class="byline" *ngIf="item._embedded.author.length"> <strong>Author: </strong> <span class="author vcard text-orange">{{item._embedded.author[0].name}}</span></span>
						<!-- <span class="cats"><strong>Posted in: </strong> <span class="text-orange">Bootstrap 4</span></span> -->
					</div><!-- .entry-meta -->
				</header><!-- .entry-header -->

				<div class="entry-content" [innerHTML]="sanitize(item.excerpt.rendered)"></div><!-- .entry-content -->
				<footer class="entry-footer">
					<a class="btn btn-bordered" [routerLink]="['/blog/' + item.slug]">Read More</a>
				</footer><!-- .entry-footer -->
			</article>
		</main>

		<nav>
			<ul class="pagination justify-content-center list-inline clearfix mb-0">
				<li [hidden]="page <= 1" class="page-item pr-2">
					<a class="pagelink" tabindex="-1" [routerLink]="['/blog/page/' + prev]">Previous</a>
				</li>
				<li [hidden]="page >= totalPages" class="page-item pl-2">
					<a class="pagelink" [routerLink]="['/blog/page/' + next]">Next</a>
				</li>
			</ul>
		</nav>
	</div><!-- END .container -->
</section>