import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../core/services';
import { LoaderState } from 'bullymake-types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  menuCollapse = false;

  public show;

  constructor(private loaderService: LoaderService) {

  }

  /**
   * Init Methods
   * @return null
   */
  ngOnInit() {

    this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }

  /**
   * Toggle Menu Menu
   * @return null
   */
  toggleMenu() {

    // Toggle Button
    let elem = document.getElementById('navbar-toggler')

    // Return if already collapsed
    if( elem.classList.contains('collapsed') ) return;

    // Trigger Click
    elem.click();
  }
}
