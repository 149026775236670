import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BullymakeError } from "bullymake-types";

@Injectable({
	providedIn: 'root'
})
export class BullymakeErrorHandler implements ErrorHandler {

    public stacks: string[] = [];

    public readonly API_ROOT: string = environment.API_ROOT;

    constructor(private http: HttpClient) {
        
    }

    handleError(error: any): void {

        // Prepare Error Message
        let message: string = '';

        // Prepare Stack trace
        let stack: string = '';

        // Prepare Error Code
        let code: string = '0';

        if( error instanceof HttpErrorResponse ) {

            message = error.message;
            stack   = JSON.stringify(error.error);
            code    = error.status.toString();
       
            // Ignore Cancelled Requests
            if( code === '0' && error.statusText === 'Unknown Error' ) return;
           
        } else if (error instanceof Error) {
                
            message = error.message;
            stack   = error.stack;
            
        } else {

            // Attempt #1 to get the error message from the error object
            if( error.error && error.error.message ) message = error.message;

            // Attempt #2 to get the error message from the error object
            if( error.message ) message = error.message;

            // Attempt #1 to get the error stack from the error object
            if( error.error ) stack = error.error;

            // Attempt #2 to get the error stack from the error object
            if( error.error && error.error.stack ) stack = error.error.stack;

            // Attempt #3 to get the error stack from the error object
            if( error.stack ) stack = error.error.stack;
        }

        // Get the first line of the stack trace
        message = message.split('\n', 1).join('');

        // Make sure to not Log the same error twice or multiple Times
        if( this.stacks.includes(stack) ) return
        
        // Add the stack to the stack array
        this.stacks.push(stack);

        // Prepare Payload
        const logErrorPayload: BullymakeError = {
            'source': 'bullymake-website',
            'error_type': 'error',
            'stack': stack,
            'message': message,
            'code': code,
        };

        this.logError(logErrorPayload).subscribe((data: {data: true}) => {
            
        });
    }

    logError(error: BullymakeError) {

        return this.http.post(this.API_ROOT + 'api/v3/log-error', error)
    }
}