import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chewers-unite',
  templateUrl: './chewers-unite.component.html',
  styleUrls: ['./chewers-unite.component.css']
})
export class ChewersUniteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
