<mat-progress-bar mode="indeterminate" class="fixed-top" [class.d-none]="!show"></mat-progress-bar>
<!-- <div class="site-loader" [class.d-none]="!show">
  <div class=" d-flex h-100 modal-backdrop">
    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
    <img class="mx-auto justify-content-center align-self-center" src="https://bullymake.com/wp-content/plugins/better-user-management/public/img/my-account-loader.gif" alt="">
  </div>
</div> -->

<div id="secondary-topbar" class="" style="background: #49BD98">
    <div class="container text-center py-1">
      <p class="mb-0 text-light text-uppercase">20% off Your First Box with code: <strong class="text-darka fw-bold">20BULLY</strong></p>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-dark mb-5 py-3">
	<div class="container d-block">
		<a class="navbar-brand" [routerLink]="['/']">
			<img class="site-icon" src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/favicon-665b0bd45e049.webp" alt="">
			<!-- <img class="site-logo" src="assets/img/logo.png" alt=""> -->
		</a>

		<div class="d-flex justify-content-end justify-content-md-center">
			<div class="checkout-steps text-center">
				<div class="step-block">
					<p class="label text-dark"></p>
					<div class="step-block-shape"></div>
				</div><!-- END .step-block -->

				<div class="step-block">
					<p class="label text-dark"></p>
					<div class="step-block-shape"></div>
				</div><!-- END .step-block -->

				<div class="step-block">
					<p class="label text-dark">1 Step Away!</p>
					<div class="step-block-shape current"></div>
				</div><!-- END .step-block -->

			</div><!-- END .checkout-steps -->
		</div><!-- END .d-flex -->
	</div><!-- END .container -->
</nav>
