<section id="primary" class="section-item">
  <div class="container">
    <!-- <h2 class="section-title text-dark text-uppercase mb-5 text-center">{{post.title.rendered}}</h2> -->

    <main id="main" class="site-main">
      <div class="icon-holder text-center mb-5" *ngIf="!post">
        <img class="article-icon" src="/assets/img/article.png" alt="">
        <h2 class="mt-3">LOADING</h2>
      </div><!-- END .icon-holder -->

      <article class="mb-5 post-item" *ngIf="post">
      	<header class="entry-header pb-3 mb-3">
          <img class="featured-image" *ngIf="post._embedded['wp:featuredmedia']" src="{{post._embedded['wp:featuredmedia'][0].source_url}}" alt="">
      		<h2 class="entry-title text-uppercase mt-3"><a class="text-dark" [routerLink]="['/blog/' + post.slug]" rel="bookmark"[innerHTML]="sanitize(post.title.rendered)"></a></h2>
  				<div class="entry-meta">
            <span class="byline" *ngIf="post._embedded.author.length && !post.hide_author"> <strong>Author: </strong> <span class="author vcard text-orange">{{post._embedded.author[0].name}}</span></span>
            <!-- <span class="cats"><strong>Posted in: </strong> <span class="text-orange">Bootstrap 4</span></span> -->
      		</div><!-- .entry-meta -->
  			</header><!-- .entry-header -->

    		<div class="entry-content" [innerHTML]="sanitize(post.content.rendered)"></div><!-- .entry-content -->
      	<footer class="entry-footer"></footer><!-- .entry-footer -->
      </article>
    </main>
  </div><!-- END .container -->
</section>
