import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { UnsubscribeComponent } from './modules-page/main/unsubscribe/unsubscribe.component';
import { RemovalComponent } from './modules-page/main/removal/removal.component';
import { BullymakeBlogComponent } from './modules-page/main/bullymake-blog/bullymake-blog.component';
import { SingleProductComponent } from './modules-page/main/single-product/single-product.component';
import { CustomizeComponent } from './modules-page/main/single-product/customize/customize.component';
import { ReviewsComponent } from './modules-page/main/reviews/reviews.component';
import { DogToyPComponent } from './modules-page/main/landing/dog-toy-p/dog-toy-p.component';
import { DogToyP2Component } from './modules-page/main/landing/dog-toy-p2/dog-toy-p2.component';
import { SinglePostComponent } from './modules-page/main/bullymake-blog/partials/single-post/single-post.component';
import { DogBoredomComponent } from './modules-page/main/landing/dog-boredom/dog-boredom.component';
import { DogGiftComponent } from './modules-page/main/landing/dog-gift/dog-gift.component';
import { WhyBullymakeAComponent } from './modules-page/main/why-bullymake/why-bullymake-a/why-bullymake-a.component';
import { WhyBullymakeBComponent } from './modules-page/main/why-bullymake/why-bullymake-b/why-bullymake-b.component';
import { WhyBullymakeCComponent } from './modules-page/main/why-bullymake/why-bullymake-c/why-bullymake-c.component';
import { DogToyAComponent } from './modules-page/main/dog-toy/dog-toy-a/dog-toy-a.component';
import { DogToyBComponent } from './modules-page/main/dog-toy/dog-toy-b/dog-toy-b.component';
import { DogToyB2Component } from './modules-page/main/dog-toy/dog-toy-b2/dog-toy-b2.component';
import { DogToyCComponent } from './modules-page/main/dog-toy/dog-toy-c/dog-toy-c.component';
import { DogToyB20Component } from './modules-page/main/dog-toy/dog-toy-b20/dog-toy-b20.component';
import { SubscriptionBoxAComponent } from './modules-page/main/subscription-box/subscription-box-a/subscription-box-a.component';
import { SubscriptionBoxBComponent } from './modules-page/main/subscription-box/subscription-box-b/subscription-box-b.component';
import { SubscriptionBoxB2Component } from './modules-page/main/subscription-box/subscription-box-b2/subscription-box-b2.component';
import { SubscriptionBoxCComponent } from './modules-page/main/subscription-box/subscription-box-c/subscription-box-c.component';
import { SubscriptionBoxB20Component } from './modules-page/main/subscription-box/subscription-box-b20/subscription-box-b20.component';
import { PromotionsComponent } from './modules-page/main/promotions/promotions.component';
import { LoginComponent } from './modules-page/main/login/login.component';
import { BedsComponent } from './modules-page/main/beds/beds.component';
import { HomeComponent } from './pages/home/home.component';
import { BlackFridayComponent } from './pages/black-friday/black-friday.component';

const appRoutes: Routes = [

	{
		path: '',
		component: HomeComponent,
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'black-friday',
		component: BlackFridayComponent,
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'accessibility',
		loadComponent: () => import('./pages/accessibility-statement/accessibility-statement.component').then((x) => x.AccessibilityStatementComponent),
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'help',
		loadComponent: () => import('./pages/help/help.component').then((x) => x.HelpComponent),
		data: { title: 'Help - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'ugc-policy',
		loadComponent: () => import('./pages/ugc-policy/ugc-policy.component').then((x) => x.UgcPolicyComponent),
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'terms-and-conditions',
		loadComponent: () => import('./pages/terms-and-conditions/terms-and-conditions.component').then((x) => x.TermsAndConditionsComponent),
		data: { title: 'Terms And Conditions - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'safety',
		loadComponent: () => import('./pages/safety/safety.component').then((x) => x.SafetyComponent),
		data: { title: 'Safety - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'refundpolicy',
		loadComponent: () => import('./pages/refund-policy/refund-policy.component').then((x) => x.RefundPolicyComponent),
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'privacy-policy',
		loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then((x) => x.PrivacyPolicyComponent),
		data: { title: 'Privacy Policy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'toys-detail',
		loadComponent: () => import('./pages/toys-detail/toys-detail.component').then((x) => x.ToysDetailComponent),
		data: { title: 'Toys Detail - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'success',
		loadComponent: () => import('./pages/success/success.component').then((x) => x.SuccessComponent),
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'stop-destructive-chewing',
		loadComponent: () => import('./pages/stop-destructive-chewing/stop-destructive-chewing.component').then((x) => x.StopDestructiveChewingComponent),
		data: { title: 'Stop Destructive Chewing - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'past-boxes',
		loadComponent: () => import('./pages/past-boxes/past-boxes.component').then((x) => x.PastBoxesComponent),
		data: { title: 'Past Boxes - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'in-the-box',
		loadComponent: () => import('./pages/in-the-box/in-the-box.component').then((x) => x.InTheBoxComponent),
		data: { title: 'In The Box - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'faq',
		loadComponent: () => import('./pages/faqs/faqs.component').then((x) => x.FaqsComponent),
		data: { title: 'FAQ - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'contact-us',
		loadComponent: () => import('./pages/contact-us/contact-us.component').then((x) => x.ContactUsComponent),
		data: { title: 'Contact Us - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'beds',
		component: BedsComponent,
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'unsubscribe',
		component: UnsubscribeComponent,
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'removal',
		component: RemovalComponent,
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'how-this-dog-company-changed-the-game-for-power-chewing-dogs',
		component: WhyBullymakeAComponent,
		data: { title: 'Why Bullymake - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'how-this-dog-company-changed-the-game-for-power-chewing-dogs-b',
		component: WhyBullymakeBComponent,
		data: { title: 'Why Bullymake - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'how-this-dog-company-changed-the-game-for-power-chewing-dogs-c',
		component: WhyBullymakeCComponent,
		data: { title: 'Why Bullymake - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'black-friday',
		component: WhyBullymakeCComponent,
		data: { title: 'Why Bullymake - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},



	{
		path: 'subscription-box',
		component: SubscriptionBoxAComponent,
		data: { title: 'Subscription Box - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'subscription-box-b',
		component: SubscriptionBoxBComponent,
		data: { title: 'Subscription Box - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'subscription-box-b2',
		component: SubscriptionBoxB2Component,
		data: { title: 'Subscription Box - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'subscription-box-c',
		component: SubscriptionBoxCComponent,
		data: { title: 'Subscription Box - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'subscription-box-20',
		component: SubscriptionBoxB20Component,
		data: { title: 'Subscription Box - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'dog-toy',
		component: DogToyAComponent,
		data: { title: 'Dog Toy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'dog-toy-b',
		component: DogToyBComponent,
		data: { title: 'Dog Toy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'dog-toy-b2',
		component: DogToyB2Component,
		data: { title: 'Dog Toy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'dog-toy-20',
		component: DogToyB20Component,
		data: { title: 'Dog Toy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'dog-toy-c',
		component: DogToyCComponent,
		data: { title: 'Dog Toy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'dog-toy-p',
		component: DogToyPComponent,
		data: { title: 'Dog Toy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'dog-toy-p2',
		component: DogToyP2Component,
		data: { title: 'Dog Toy - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'reviews',
		component: ReviewsComponent,
		data: { title: 'Reviews - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'blog',
		component: BullymakeBlogComponent,
		data: { title: 'Bullymake Blog - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'blog/page/:page',
		component: BullymakeBlogComponent,
		data: { title: 'Bullymake Blog - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'blog/:slug',
		component: SinglePostComponent,
		data: { title: 'Bullymake Blog - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'signin',
		component: LoginComponent,
		data: { title: 'Signin - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-month-subscription',
		component: SingleProductComponent,
		data: { id: 3276883, title: '1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/3-month-subscription',
		component: SingleProductComponent,
		data: { id: 3276878, title: '3 Months Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/6-month-subscription',
		component: SingleProductComponent,
		data: { id: 3276880, title: '6 Months Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-year-subscription',
		component: SingleProductComponent,
		data: { id: 3276881, title: '1 Year Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/monthly-pay-plan',
		component: SingleProductComponent,
		data: { id: 59533, title: 'Multi - Month Subscription Using Monthly Pay - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-month-subscription/customize', // TODO: Remove this
		component: CustomizeComponent,
		data: { id: 3276883, title: 'Customize - 1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/3-month-subscription/customize',
		component: CustomizeComponent,
		data: { id: 3276878, title: 'Customize - 3 Months Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/6-month-subscription/customize',
		component: CustomizeComponent,
		data: { id: 3276880, title: 'Customize - 6 Months Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-year-subscription/customize',
		component: CustomizeComponent,
		data: { id: 3276881, title: 'Customize - 1 Year Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/monthly-pay-plan/customize',
		component: CustomizeComponent,
		data: { id: 59533, title: 'Customize - Multi - Month Subscription Using Monthly Pay - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-month-subscription/promotion',
		component: SingleProductComponent,
		data: { id: 3276883, title: '1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-month-subscription/promotion-b',
		component: SingleProductComponent,
		data: { id: 3276883, title: '1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-month-subscription/promotion-c',
		component: SingleProductComponent,
		data: { id: 3276883, title: '1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},

	{
		path: 'product/1-month-subscription/promotion-fb20',
		component: SingleProductComponent,
		data: { id: 3276883, title: '1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},



	{
		path: 'dog-boredom',
		component: DogBoredomComponent,
		data: { title: 'Dog Boredom - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'dog-gift',
		component: DogGiftComponent,
		data: { title: 'Dog Gift - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},
	{
		path: 'promotion',
		component: PromotionsComponent,
		data: { title: 'Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	},



	{
		path: 'w9',
		data: { title: 'W9 / W-8BEN Forms - Bullymake Box - A Dog Subscription Box For Power Chewers!' },
		loadChildren: () => import('./modules-page/w9/w9.module').then(m => m.W9Module)
	},

	{
		path: 'subscribe',
		loadChildren: () => import('./modules-page/subscribe/subscribe.module').then(m => m.SubscribeModule)
	},

	{
		path: 'my-account',
		data: { title: 'My Account - Bullymake Box - A Dog Subscription Box For Power Chewers!' },

		canActivate: [AuthGuard],
		loadChildren: () => import("./modules-page/my-account/my-account.module").then((m) => m.MyAccountModule)
	},
	{
		path: 'checkout',
		data: { title: 'Checkout - Bullymake Box - A Dog Subscription Box For Power Chewers!' },
		loadChildren: () => import("./modules-page/checkout/checkout.module").then((m) => m.CheckoutModule)
	},

	{
		path: '**',
		loadComponent: () => import('./pages/not-found/not-found.component').then((x) => x.NotFoundComponent),
		data: { title: 'Page not found - Bullymake Box - A Dog Subscription Box For Power Chewers!' }
	}

];
export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
