import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-box-a',
  templateUrl: './subscription-box-a.component.html',
  styleUrls: ['./subscription-box-a.component.css']
})
export class SubscriptionBoxAComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
