<mat-dialog-content class="text-center">
  <h2 class="mt-3 mb-3 text-uppercase text-orange">Hello EU Visitor!</h2>
  <p>We respect your privacy 100% and comply with GDPR.</p>
  <p>To use our site and shop for your power chewing pup, please accept these terms:</p>

  <ul class="text-left">
    <li>
      You accept us to place cookies which will never contain personally identifiable
      information about you - ever.</li>
    <li>
      You accept us to send you marketing emails should you write your email anywhere
      on this site.
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="text-center d-block w-100 mb-2">
    <button class="btn btn-lg btn-orange m-1" (click)="close('yes')">YES I ACCEPT</button>
    <button class="btn btn-lg btn-bordered m-1" (click)="close('no')">NO - I’LL LEAVE</button>
  </div>
</mat-dialog-actions>
