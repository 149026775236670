<button [type]="type" class="btn" [disabled]="isLoading" [ngClass]="{
                    'btn-sm ': size === 'sm',
                    'btn-md w-50': size === 'md',
                    'btn-lg w-100': size === 'lg',

    'btn-primary': color === 'primary',
    'btn-secondary': color === 'secondary',
 
    'btn-secondary-loader':(isLoading && (color === 'secondary')),
    'btn-primary-loader':(isLoading && (color === 'primary'))
}">
    <ng-content>
    </ng-content><!-- END ng-content -->

    <ng-container *ngIf="isLoading;">
        <span class="btn-spinner" [ngClass]="{
    'btn-secondary-spinner': (color === 'secondary'),
    'btn-primary-spinner': (color === 'primary')
}">
            <span class="spinner-border" [ngClass]="{
    'btn-sm-spinner': size === 'sm',
    'btn-md-spinner': size === 'md',
    'btn-lg-spinner': size === 'lg'
}">
            </span><!-- END .spinner-border -->
        </span><!-- END .btn-spinner -->
    </ng-container><!-- END ng-container -->

</button>