import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dog-toy-c',
  templateUrl: './dog-toy-c.component.html',
  styleUrls: ['./dog-toy-c.component.css']
})
export class DogToyCComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
