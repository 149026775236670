import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-box-c',
  templateUrl: './subscription-box-c.component.html',
  styleUrls: ['./subscription-box-c.component.css']
})
export class SubscriptionBoxCComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
