import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-box-b',
  templateUrl: './subscription-box-b.component.html',
  styleUrls: ['./subscription-box-b.component.css']
})
export class SubscriptionBoxBComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
