import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@Component({
  selector: 'app-black-friday',
  templateUrl: './black-friday.component.html',
  styleUrls: ['./black-friday.component.css'],
  standalone: true,
  imports: [
	RouterModule,
	AccordionModule
  ]
})
export class BlackFridayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
