import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from 'bullymake-types';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	public readonly API_ROOT: string = environment.API_ROOT;

	constructor(private http: HttpClient) { }

	login(username: string, password: string): Observable<LoginResponse> {

		return this.http.post<LoginResponse>(this.API_ROOT + 'jwt-auth/v1/token', { username: username, password: password })
	}
}
