import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BullymakeBlogService } from './bullymake-blog.service';

@Component({
	selector: 'app-bullymake-blog',
	templateUrl: './bullymake-blog.component.html',
	styleUrls: ['./bullymake-blog.component.css']
})
export class BullymakeBlogComponent implements OnInit {

	public loaded: boolean = false;

	public totalPages: number = 0;

	public prev: number = 1;

	public page: number = 1;

	public next: number = 2;

	posts = [];

	constructor(private route: ActivatedRoute, private service: BullymakeBlogService, public sanitizer: DomSanitizer) { }

	ngOnInit() {

		this.route.params
			.subscribe(params => {
				if (params.page) this.page = params.page;
				this.getPosts(Number(this.page));
			});
	}

	getPosts(page) {
		this.posts = [];
		this.service.getPosts(page)
			.subscribe((res) => {
				this.totalPages = Number(res.headers.get('x-wp-totalpages'));
				this.posts = res.body;

				this.loaded = true;
				this.next = page + 1;
				if (page > 1) this.prev = page - 1;
			});
	}

	sanitize(html: string) {

		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

}
