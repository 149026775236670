import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataStoreService } from '../../../core/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { LoginService } from './login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginResponse } from 'bullymake-types';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	/**
	 * Login Form
	 */
	public form: UntypedFormGroup = this.formBuilder.group({
		username: ['', Validators.required],
		password: ['', Validators.required]
	});

	/**
	 * Helper to check if form submission is attempted
	 */
	public submittedAttempted: boolean = false;

	/**
	 * Get Redirect URL After Successful Login
	 */
	public returnUrl: string = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/my-account';

	/**
	 * Helper to check if HTTP Request is in progress
	 */
	public isLoading = false;

	/**
	 *
	 * @param formBuilder FormBuilder
	 * @param activatedRoute ActivatedRoute
	 * @param router Router
	 * @param dataStore DataStoreService
	 * @param authService AuthService
	 * @param snackBar MatSnackBar
	 * @param service LoginService
	 */
	constructor(
		private formBuilder: UntypedFormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dataStore: DataStoreService,
		private authService: AuthService,
		public snackBar: MatSnackBar,
		private service: LoginService) { }

	/**
	 * On Init Lifecycle Hook
	 */
	ngOnInit() {

	}

	/**
	 *
	 * @param form FormGroup
	 * @returns
	 */
	public login(form: UntypedFormGroup) {

		// Set Submitted Attempted to true
		this.submittedAttempted = true;

		// Case: Form is invalid
		if (this.form.invalid) return;

		// Set loading to true
		this.isLoading = true;

		// Send Auth Request
		this.service.login(form.value.username, form.value.password)
			.pipe(
				map((response: LoginResponse): LoginResponse => {

					// Return User
					return response;
				})
			)
			.subscribe((data: LoginResponse) => {

				this.dataStore.customer$.next(data.customer);

				// Save User to Local Storage
				this.authService.setToken(data.token);

				// Redirect to the Return URL
				this.router.navigate([this.returnUrl]);

				// @ts-ignore
				window.dataLayer.push({
					'event': 'login',
					'customer_details': {
						'email': data.customer.email,
					}
				});

			}, (error: HttpErrorResponse) => {

				// Hide Loader
				this.isLoading = false;

				// Prepare Snackbar Message
				const message: string = (error.status == 403) ? 'Invalid Credentials. Please Try Again or Reset Password' : error.error.message;

				// Show Error Snackbar
				this.snackBar.open(message, 'Close', {
					duration: 3500,
				});
			});
	}
}
