import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-bullymake-c',
  templateUrl: './why-bullymake-c.component.html',
  styleUrls: ['./why-bullymake-c.component.css']
})
export class WhyBullymakeCComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
