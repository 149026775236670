<section id="sponsoredIn" class="section-item bb text-light py-4">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img class="img-fluid filterImg" src="./assets/img/the-washington-post.png" alt="">
            </div><!-- END .col-sm-4 -->

            <div class="col-sm-4">
                <img class="img-fluid filterImg" src="./assets/img/pet-faves.png" alt="">
            </div><!-- END .col-sm-4 -->

            <div class="col-sm-4">
                <img class="img-fluid filterImg" src="./assets/img/my-subscription-addiction.png" alt="">
            </div><!-- END .col-sm-4 -->
        </div><!-- END .row -->
    </div><!-- END .container -->
</section><!-- END .section-item -->