/**
 * Get All Dates between two dates
 * @param startDate Date
 * @param endDate Date
 * @returns string[]
 */
export function getDatesInRange(startDate: Date, endDate: Date): Date[] {

    // Copy dates
    const date = new Date(startDate.getTime());

    // Create dates array
    const dates = [];

    // Loop through dates
    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    // Return dates
    return dates;
}

/**
 * Format Date to YYYY-MM-DD
 * @param date Date
 * @returns string
 */
export function formatDateToString(date: Date): string {

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * Converts String to Array
 * @param str string
 * @param delimeter string
 * @returns 
 */
export function stringToArray(str: string, delimeter: string = ','): string[] {

	// Split String by Delimeter and Trim each item
	const arr: string[] = str.split(delimeter).map(item => item.trim());

	// Filter Empty Items
	const filtered: string[] = arr.filter((item: string) => item);

	// Return Filtered Array
	return filtered;
}

/**
 * Converts Array to String
 * @param arr string[]
 * @param delimeter string
 * @returns string
 */
export function arrayToString(arr: string[], delimeter: string = ','): string {

	// Trim each item
	arr = arr.map(item => item.trim());

	// Filter Empty Items
	arr = arr.filter((item: string) => item);

	// Return String
	return arr.join(delimeter);
}