<section id="section1" class="section-item h-100">
	<div class="container justify-content-center h-100">
		<h2 class="section-title text-dark text-uppercase mb-3 text-center">Signin</h2>
		<div class="section-content mb-5 mt-5">
			<form [formGroup]="form" (ngSubmit)="login(form)">
				<div class="form-group">
					<mat-form-field class="w-100">
						<mat-label>Username or Email</mat-label>
						<input matInput placeholder="Username or Email" formControlName="username">
						<mat-error *ngIf="form.controls.username.hasError('required')">Username or Email is <strong>required</strong></mat-error>
					</mat-form-field>
				</div><!-- END .form-group -->

				<div class="form-group mt-2">
					<mat-form-field class="w-100">
						<mat-label>Password</mat-label>
						<input matInput type="password" placeholder="Password" formControlName="password">
						<mat-error *ngIf="form.controls.password.hasError('required')">Password is <strong>required</strong></mat-error>
					</mat-form-field>
				</div><!-- END .form-group -->

				<mat-checkbox mt-2>Remember Me</mat-checkbox>
				<button type="submit" class="text-uppercase w-100 font-primary btn btn-lg mt-3">Signin</button>

				<div class="mt-4 form-actions text-center">
					<a class="" href="/wp-login.php?action=lostpassword">Forgot Password?</a>
				</div><!-- END .form-actions -->
			</form>
		</div><!-- END .section-content -->
	</div><!-- END .container -->
</section><!-- END #section1 -->