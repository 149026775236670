import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleReview } from 'bullymake-types';


@Component({
  selector: 'app-google-review',
  templateUrl: './google-review.component.html',
  styleUrls: ['./google-review.component.css']
})
export class GoogleReviewComponent implements OnInit {

  // Getting Review Object as Input from Parent Component
  @Input() review: GoogleReview;
  
  // Getting Reference of rating div ie. #rating
  @ViewChild('rating') rating: ElementRef;

  // Initialize Avatar with default placeholder
  public avatar: string = 'assets/img/greviews-avatar.jpg';
  
  // Initialize MAX_COMMENT_LENGTH of characters when user not click on show more
  public MAX_COMMENT_LENGTH = 250;

  // Initialize maxCommentLength 
  public maxCommentLength: number = 0;

  // Initialize Show Hide Label for Button
  public showHide: string = 'show more';

  // Initialize isVisible For Toggling ShowMore and ShowLess
  public isVisible: boolean = false;

  // Initialize default
  public maxTextLength: number = this.MAX_COMMENT_LENGTH;

  /**
   * 
   * @param renderer2 Renderer2 for DOM Manipulation
   */
  constructor(
    private renderer2: Renderer2
  ) { }

  ngOnInit(): void {

    // Initialize avatar from DB 
    this.avatar = this.review.reviewer_profile_photo_url;

    // Initialize maxCommentLength from DB
    this.maxCommentLength = this.review.comment ? this.review.comment.length : this.MAX_COMMENT_LENGTH;

  }


  ngAfterViewInit() {

    // Add attribute with rating value for rating label
    this.renderer2.setAttribute(this.rating.nativeElement, 'data-content', this.review.rating + '.0');

  }

  setDefaultAvatar() {

    // Initialize avatar with default img
    this.avatar = 'assets/img/greviews-avatar.jpg';
 
  }

  toggleShowHide() {

    // Toggle isVisible
    this.isVisible = !this.isVisible;


    if (this.isVisible) {

      this.maxTextLength = this.maxCommentLength;

      this.showHide = 'show less';

    }
    else {

      this.maxTextLength = this.MAX_COMMENT_LENGTH;

      this.showHide = 'show more';

    }

  }




}
