import { Injectable } from '@angular/core';
import { Notification } from 'bullymake-types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationSubject = new Subject<Notification>();
  notificationState = this.notificationSubject.asObservable();

  constructor() { }

  pushNotification(Data) {
    this.notificationSubject.next(<Notification>Data);
  }
}
