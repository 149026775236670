import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { API_Response, Cart, Checkout } from 'bullymake-types';

import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CartService {

	/**
	 * API Root URL
	 */
	public readonly API_ROOT: string = environment.API_ROOT;

	/**
	 * Class Constructor
	 * 
	 * @param http HttpClient
	 */
	public constructor(private http: HttpClient) { }

	/**
	 * Update Cart
	 * 
	 * @param cart Cart
	 * @returns Observable<API_Response<OrderReview>>
	 */
	updateCart(cart: Cart): Observable<API_Response<Checkout>> {
		
		// Return Observable
		return this.http.post<API_Response<Checkout>>(this.API_ROOT + 'api/v3/cart/', cart);
	}
}
