import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-box-b2',
  templateUrl: './subscription-box-b2.component.html',
  styleUrls: ['./subscription-box-b2.component.css']
})
export class SubscriptionBoxB2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
