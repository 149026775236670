import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { NotificationService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class NotificationInterceptor implements HttpInterceptor {

	constructor(private notificationService: NotificationService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(request).pipe(
			tap((event: HttpResponse<any>) => {
			
				if (event instanceof HttpResponse && event.status == 200) {

					// TODO: OLD Will be removed in future
					if (event.body.notification) { this.notificationService.pushNotification(event.body.notification); }

					if (event.body.toast && event.body.toast.scope == 'global') {

						this.notificationService.pushNotification({message: event.body.toast.message, type: event.body.toast.type});
					}
				}

				return next.handle(request);
			}),
			catchError((error: HttpErrorResponse) => {
				
				if (error.error.toast && error.error.toast.scope == 'global') {

					this.notificationService.pushNotification({message: error.error.toast.message, type: error.error.type});
				}

				// TODO: Remove Later
				if (error.error.data?.toast && error.error.data.toast.scope == 'global') {

					this.notificationService.pushNotification({message: error.error.data.toast.message, type: error.error.data.type});
				}

				return throwError(error);
			})
		);
	}
}
