import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeService } from './unsubscribe.service';

@Component({
  selector: 'app-help',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private unsubscribeService: UnsubscribeService
  ) { }

  ngOnInit() {
    const userEmail = this.route.snapshot.queryParams['e'] || null;

    if (userEmail) {
      this.unsubscribeService.process(userEmail).subscribe();
    }
  }

}
