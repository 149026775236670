import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AllowedCountry, Cart, API_Response, Customer, AlleryOption, BoxOption } from 'bullymake-types';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class DataStoreService {

	public readonly API_ROOT: string = environment.API_ROOT

	/**
	 * Customer BehaviorSubject
	 */
	public customer$: BehaviorSubject<Customer | null> = new BehaviorSubject<Customer | null>(null);

	/**
	 * Customer Observable
	 */
	public customer: Observable<Customer | null> = this.customer$.asObservable();

	/**
	 * Cart BehaviorSubject
	 */
	private cart$: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(null);

	private products$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	private checkout$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	// guided-subscription-options

	/**
	 * Cart Observable
	 */
	public cart: Observable<Cart> = this.cart$.asObservable();

	// private products$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	/**
	 * Update Cart BehaviorSubject
	 * @param cart Cart
	 */
	public updateCart(cart: Cart) {

		// Update Cart BehaviorSubject
		this.cart$.next(cart);
	}

	constructor(private http: HttpClient) { }

	/**
	 * Box Options for Select Dropdown
	 */
	public boxOptions: BoxOption[] = [
		{ label: 'Toys & Treats', value: 'toys-and-treat' },
		{ label: 'Toys Only (+ $5)', value: 'toys-only' },
	];

	/**
	 * Allergy Options for Select Dropdown
	 */
	public allergyOptions: AlleryOption[] = [
		{ 'label': 'No Allergies', 'value': 'no-allergies' },
		{ 'label': 'Grain Allergy', 'value': 'grain' },
		{ 'label': 'Beef / Grain Allergy', 'value': 'beef-grain' },
		{ 'label': 'Chicken / Grain Allergy', 'value': 'chicken-grain' }
	];

	public allowedCountries$: BehaviorSubject<AllowedCountry[]> = new BehaviorSubject<AllowedCountry[]>([]);

	public allowedCountries: Observable<AllowedCountry[]> = this.allowedCountries$.asObservable();

	public getAllowedCountries(): Observable<API_Response<AllowedCountry[]>> {

		return this.http.get<API_Response<AllowedCountry[]>>( this.API_ROOT + 'api/v3/utilities/allowed-countries/' );
	}

	public setAllowedCountries() {

		if( this.allowedCountries$.value.length ) return;

		this.getAllowedCountries().subscribe((response: API_Response<AllowedCountry[]>) => {
			
			this.allowedCountries$.next(response.data);
		});
	}
}
