<section id="section1" class="section-item bg-orange">
  <div class="container">
    <div class="section-content">
      <div class="process-status text-light">
        <div class="status-item status1 d-inline-block text-center active">
          <img class="indicator" src="assets/img/single-product/active.svg" alt="Active Step">
          <span class="status-label">First Box</span>
        </div><!-- END .status1 -->

        <div class="status-item status2 d-inline-block text-center">
          <span class="status-label">Subscribe</span>
        </div><!-- END .status2 -->

        <div class="status-item status3 d-inline-block text-center">
          <span class="status-label">Done!</span>
        </div><!-- END .status3 -->
      </div><!-- END .process-status -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item text-center">
  <div class="container">
    <div class="section-content">
      <img class="img-fluid" src="assets/img/single-product/happy-dogs.png" alt="Happy Dogs">
      <h1 class="mt-5">NOW JOINING OVER 100,000+ HAPPY DOGS!</h1>
      <h2 class="text-orange text-uppercase mt-3">Only the best for your power chewer.</h2>
      <p class="mt-3">We’ll make sure of it!</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->

</section><!-- END #section2 -->
<section id="section3" class="section-item bg-dark text-light">
  <div class="container">
    <h2 class="section-title text-orange text-uppercase mb-3">Size</h2>
    <div class="section-content">
      <p>Knowing your dog’s weight helps us send you better toys.</p>

      <h5 class="text-orange text-uppercase mt-5">Weight</h5>
      <mat-slide-toggle [(ngModel)]="unit" (change)="updateUnit($event, selection);" class="mt-3">Switch to KG</mat-slide-toggle>
      <div class="row mt-3">
        <div class="col-sm-2">
          <div class="column-content">
            <span [hidden]="cart.selection.unit == 'kg'" class="lead text-orange mt-2">{{cart.selection.weight}} lbs</span>
            <span [hidden]="cart.selection.unit == 'lbs'" class="lead text-orange mt-2">{{cart.selection.weight * 0.453592 | number:'1.0-0'}} kg</span>
          </div><!-- END .column-content -->
        </div><!-- END .col-sm-2 -->

        <div class="col-sm-10">
          <div class="column-content">
            <mat-slider thumbLabel [(ngModel)]="cart.selection.weight" class="slider-light w-100 font-secondary" min="10" max="200"></mat-slider>
          </div><!-- END .column-content -->
        </div><!-- END .col-sm-10 -->
      </div><!-- END .row -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section4" class="section-item bg-light">
  <div class="container">
    <h2 class="section-title text-uppercase mb-3">LET’S BUILD THE BEST BOX FIDO’S EVER GOTTEN.</h2>
    <div class="section-content">
      <label class="label-item" for="">Please send Fido...</label>
      <ul class="selection-group">
        <li class="input-item" *ngFor="let item of fields.boxContent" [ngClass]="{active: cart.selection.box_items == item.value}">
          <a (click)="cart.selection.box_items = item.value">{{item.label}}</a>
          <span class="tool-tip d-none d-md-inline-block" matTooltip="{{item.tooltip}}" matTooltipPosition="above">?</span>
          <span class="tool-tip d-md-none" matTooltip="{{item.tooltip}}" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">?</span>
        </li>
      </ul><!-- END .selection-group -->

      <label class="label-item mt-4" for="">Fido’s allergies*</label>
      <ul class="selection-group">
        <li class="input-item" *ngFor="let item of fields.allergies" [ngClass]="{active: cart.selection.allergies == item.value}"><a (click)="cart.selection.allergies = item.value">{{item.label}}</a></li>
      </ul><!-- END .selection-group -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->

<section id="section4" class="section-item">
  <div class="container">
    <div class="section-content">
      <div class="row">
        <div class="col-sm-6">
          <div class="column-content">
            <h2 class="h1 mt-1">BULLYMAKE’S FINEST</h2>
          </div><!-- END .column-content -->
        </div><!-- END .col-sm-6 -->

        <div class="col-sm-6">
          <div class="column-content">
            <p class="lead text-orange">Stop getting “only one good toy per month.” Choose the perfect toys for Fido, below.</p>
          </div><!-- END .column-content -->
        </div><!-- END .col-sm-6 -->
      </div><!-- END .row -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->

<section id="section5" class="section-item bg-dark text-light text-center">
  <div class="container">
    <div class="section-content">
      <i class="play-button" (click)="openDialog()"></i>
    </div><!-- END .section-content -->
    <h3 class="section-title text-orange text-uppercase mb-3 mt-4">Need help deciding? <br> <span class="text-light">Watch this.</span></h3>
  </div><!-- END .container -->
</section><!-- END #section5 -->

<section id="section6" class="section-item bg-light">
  <div class="container">
    <div class="section-content">
      <div class="field-group">
        <label class="label-item" for="">What’s the first kind of toy Fido would like?</label>
        <ul class="selection-group">
          <li class="input-item" *ngFor="let item of fields.toys" [ngClass]="{active: cart.selection.first_toy == item.value}"><a (click)="cart.selection.first_toy = item.value">{{item.label}}</a>
            <span class="tool-tip d-none d-md-inline-block" matTooltip="{{item.tooltip}}" matTooltipPosition="above">?</span>
            <span class="tool-tip d-md-none" matTooltip="{{item.tooltip}}" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">?</span>
          </li>
        </ul><!-- END .selection-group -->
      </div><!-- END .field-group -->

      <div class="field-group">
        <label class="label-item mt-4" for="">What’s the second kind of toy Fido would like?</label>
        <ul class="selection-group">
          <li class="input-item" *ngFor="let item of fields.toys" [ngClass]="{active: cart.selection.second_toy == item.value}"><a (click)="cart.selection.second_toy = item.value">{{item.label}}</a>
            <span class="tool-tip d-none d-md-inline-block" matTooltip="{{item.tooltip}}" matTooltipPosition="above">?</span>
            <span class="tool-tip d-md-none" matTooltip="{{item.tooltip}}" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">?</span>
          </li>
        </ul><!-- END .selection-group -->
      </div><!-- END .field-group -->

      <div class="field-group" *ngIf="cart.selection.box_items == 'toys-only'">
        <label class="label-item mt-4" for="">What’s the third kind of toy Fido would like?</label>
        <ul class="selection-group">
          <li class="input-item" *ngFor="let item of fields.toys" [ngClass]="{active: cart.selection.third_toy == item.value}"><a (click)="cart.selection.third_toy = item.value">{{item.label}}</a>
            <span class="tool-tip d-none d-md-inline-block" matTooltip="{{item.tooltip}}" matTooltipPosition="above">?</span>
            <span class="tool-tip d-md-none" matTooltip="{{item.tooltip}}" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">?</span>
          </li>
        </ul><!-- END .selection-group -->
      </div><!-- END .field-group -->

      <div class="field-group" *ngIf="cart.selection.box_items == 'toys-only'">
        <label class="label-item mt-4" for="">What’s the fourth kind of toy Fido would like?</label>
        <ul class="selection-group">
          <li class="input-item" *ngFor="let item of fields.toys" [ngClass]="{active: cart.selection.fourth_toy == item.value}"><a (click)="cart.selection.fourth_toy = item.value">{{item.label}}</a>
            <span class="tool-tip d-none d-md-inline-block" matTooltip="{{item.tooltip}}" matTooltipPosition="above">?</span>
            <span class="tool-tip d-md-none" matTooltip="{{item.tooltip}}" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">?</span>
          </li>
        </ul><!-- END .selection-group -->
      </div><!-- END .field-group -->

      <div class="field-group">
        <label class="label-item mt-4" for="">More than one dog? Is Fido Extra Playful? Add an additional toy every month for just $12/month.</label>
        <ul class="selection-group">
          <li class="input-item" *ngFor="let item of fields.extraToy" [ngClass]="{active: cart.selection.add_extra_toy == item.value}"><a (click)="cart.selection.add_extra_toy = item.value">{{item.label}}</a></li>
        </ul><!-- END .selection-group -->
      </div><!-- END .field-group -->

      <div class="field-group" [hidden]="cart.selection.add_extra_toy == 'no'">
        <label class="label-item mt-4" for="">What’s the extra toy Fido would like?</label>
        <ul class="selection-group">
          <li class="input-item" *ngFor="let item of fields.toys" [ngClass]="{active: cart.selection.extra_toy == item.value}"><a (click)="cart.selection.extra_toy = item.value">{{item.label}}</a>
            <span class="tool-tip d-none d-md-inline-block" matTooltip="{{item.tooltip}}" matTooltipPosition="above">?</span>
            <span class="tool-tip d-md-none" matTooltip="{{item.tooltip}}" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">?</span>
          </li>
        </ul><!-- END .selection-group -->
      </div><!-- END .field-group -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section6 -->

<section id="section7" class="section-item text-center">
  <div class="container">
    <div class="section-content pt-4">
      <button class="uppercase btn btn-lg font-primary text-uppercase" (click)="updateCart(cart);">Lets do it</button>
      <div class="mt-3"><a class="" [routerLink]="'../'">Cancel</a></div>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section7 -->