<section id="section1" class="section-item">
  <div class="container">
    <h4 class="section-title text-dark text-uppercase mb-3 text-center">
      You have been unsubscribed
    </h4>

    <div class="section-content mt-5">
      <h6 class="mt-4 font-secondary text-center">
        Your email address has been unsubscribed from our mailings.
      </h6>

    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->
