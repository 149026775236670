import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_Response, Cart, Checkout } from 'bullymake-types';
import { CartService, ProductsService } from '../../../core/services';


@Component({
	selector: 'app-promotions',
	templateUrl: './promotions.component.html',
	styleUrls: ['./promotions.component.css'],
	host: { 'class': '' }
})
export class PromotionsComponent implements OnInit, OnDestroy {

	/**
	 * Subscriptions Array
	 */
	public subscriptions: Array<Subscription> = [];

	/**
	 * Class Constructor
	 * @param activatedRoute ActivatedRoute
	 * @param router Router
	 * @param cartService CartService
	 * @param productService ProductsService
	 * @param renderer Renderer2
	 */
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private cartService: CartService,
		private productService: ProductsService,
		private renderer: Renderer2) { }

	ngOnInit() {

		// Add Body Class
		this.renderer.addClass(document.body, 'no-header');

		// Add Body Class
		this.renderer.addClass(document.body, 'no-footer');

		// Subscribe to Activated Route Fragment
		const fragment$ = this.activatedRoute.fragment
			.subscribe((coupon: string) => {

				// Return if Coupon Fragment is Missing
				if (!coupon) return;

				// Hardcoded Product Id
				const productId: number = 3276883;

				// Add to Cart and Apply Coupon
				this.process(productId, coupon);
			});

		// Subscribe to Activated Route Query Params
		let queryParams$ = this.activatedRoute.queryParams.subscribe((params: any) => {

			// Return if Coupon or Product Query Param is Missing
			if (!params.product || !params.coupon) return;

			// Add to Cart and Apply Coupon
			this.process(params.product, params.coupon);
		})

		// Add Subscriptions to Array
		this.subscriptions.push(fragment$, queryParams$);
	}

	/**
	 * OnDestroy Lifecycle Hook
	 */
	ngOnDestroy(): void {

		// Remove Body Class
		this.renderer.removeClass(document.body, 'no-header');

		// Remove Body Class
		this.renderer.removeClass(document.body, 'no-footer');

		// Unsubscribe from Subscriptions
		this.subscriptions.forEach((subscription: Subscription) => { subscription.unsubscribe(); })
	}

	/**
	 * 
	 * @param productId number
	 * @param coupon string
	 */
	public process(productId: number, coupon: string) {

		// Setup Cart Object
		const cart: Cart = {
			product_id: productId,
			quantity: 1,
			coupon: coupon,
			selection: this.productService.getDefaultSelection()
		}

		localStorage.setItem('__bm_cart', JSON.stringify(cart));

		/**
		 * Update Cart with Product and Coupon Code
		 */
		this.cartService.updateCart(cart)
			.pipe(
				map((response: API_Response<Checkout>): Checkout => {

					// Return `Checkout`
					return response.data;
				})
			)
			.subscribe((data: Checkout) => {

				// Redirect to Checkout
				this.router.navigate(['/checkout']);

			}, (err: HttpErrorResponse) => {

			});
	}
}