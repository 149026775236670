<!-- Carousel and Banner Section -->
<section id="section-1" class="section-item">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img class="img-fluid" style="width:200px;" src="./assets/img/dog-beds/Introducing.png" alt="">
                <h1 class="text-primary text-uppercase fw-bold mt-2">Insanely Tough <br class="d-md-block d-none">Dog Beds</h1>

                <div class="d-flex align-items-center mt-5">
                    <a href="https://bullymakeshop.com/product-category/bullymake-beds/" class="btn btn-lg btn-filled shadow">Shop Now</a>
                    <h6 class="ml-5 mb-0">Made in the USA <img src="./assets/img/dog-beds/dog-bed-usa.png" class="ml-2" width="50px" alt="USA flag"></h6>
                </div>
            </div><!-- END .col-md-6 -->

            <div class="col-md-6">
                <carousel [showIndicators]="false" [isAnimated]="true" [interval]="0" class="block-controls">
                    <slide>
                        <img class="img-fluid" src="./assets/img/dog-beds/hero.png" alt="">
                    </slide>

                    <slide>
                        <img class="img-fluid" src="./assets/img/dog-beds/hero1.png" alt="">
                    </slide>

                    <slide>
                        <img class="img-fluid" src="./assets/img/dog-beds/hero3.png" alt="">
                    </slide>
                </carousel>
            </div><!-- END .col-md-6 -->
        </div><!-- END .row -->
    </div><!-- END .container -->
</section><!-- END #section-1 -->

<section id="section-2" class="section-item">
    <div class="container">
        <div class="text-center text-white">
            <h1 class="text-uppercase">Premium durable dog beds by Bullymake</h1>
            <p class="lead">For Dogs That Play Rough</p>
        </div><!-- END .text-center -->
    </div><!-- END .container -->
</section><!-- END #section-2 -->

<section id="section-3" class="section-item">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6 my-4 text-md-left text-center p-3 p-md-0">
                <div class="mt-md-5">
                    <h1 class="text-uppercase display-5"><span class="text-primary">Premium and durable</span><br> for all sizes</h1>
                    <p class="my-4 pr-md-5">They feature orthopedic memory foam rounds bodies covered in a 1680 denier ballistic shell that's not only durable on diggers and biters but cleans up nicely if a little extra sleepy drool happens.</p>
                    <a href="https://bullymakeshop.com/product-category/bullymake-beds/" class="btn btn-lg btn-filled shadow">Shop Now</a>
                </div>
            </div>

            <div class="col-12 col-lg-6 my-4 p-2 p-md-0">
                <img class="img-fluid" src="./assets/img/dog-beds/premium-and-durable.jpg" alt="">
            </div>
        </div><!-- END .row -->
    </div><!-- END .container -->
</section><!-- END #section-3 -->

<section id="section-4" class="section-item pt-0">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6 my-4 ">
                <carousel class="dogBedItemCarousel" [showIndicators]="true" [interval]="0">
                    <slide>
                        <div class="position-relative">
                            <span class="p-2 text text-white text-uppercase position-absolute bg-teal">50-80 <small>LBS</small></span>
                            <img class="img-fluid w-100" src="./assets/img/dog-beds/Large-Dog.jpg">
                            <h3 class="p-3 m-0 text-center text-uppercase bg-grey">Large Dogs</h3>
                        </div>
                    </slide>
                </carousel>

                <div class="text-center">
                    <a class="btn btn-lg btn-filled shadow mt-4" href="https://bullymakeshop.com/product-category/bullymake-beds/">Shop Now</a>
                </div>
            </div>

            <div class="col-12 col-lg-6 my-4 ">
                <carousel class="dogBedItemCarousel" [showIndicators]="true" [interval]="0">
                    <slide>
                        <div class="position-relative">
                            <span class="p-2 text text-white text-uppercase position-absolute bg-teal">20-49 <small>LBS</small></span>
                            <img class="img-fluid w-100" src="./assets/img/dog-beds/Medium-Dog.jpg">
                            <h3 class="p-3 m-0 text-center text-uppercase bg-grey">Medium Dogs</h3>
                        </div>
                    </slide>
                </carousel>

                <div class="text-center">
                    <a class="btn btn-lg btn-filled shadow mt-4" href="https://bullymakeshop.com/product-category/bullymake-beds/">Shop Now</a>
                </div>
            </div>
        </div><!-- END .row -->
    </div><!-- END .container -->
</section><!-- END #section-4 -->

<section id="section-5" class="section-item">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/tFfBKd-psjQ" allowfullscreen></iframe>
                </div>
            </div>
        </div><!-- END .row -->
    </div><!-- END .container -->
</section><!-- END #section-5 -->

<section id="section-6" class="section-item">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <img class="img-fluid d-block mx-auto mb-5" src="./assets/img/dog-beds/callout.svg" alt="">
                <div class="shadow bg-white">
                    <h3 class="p-3 text-white text-center text-uppercase bg-teal">Features of the dog bed</h3>

                    <ul class="w-100 list-unstyled d-flex flex-column justify-content-start p-0">
                        <li class="d-flex align-items-center justify-content-start p-1">
                            <img src="./assets/img/dog-beds/icon-reliable.svg" alt="Reliable" />
                            <div class="d-flex flex-column align-items-start justify-content-start">
                                <h4 class="text-uppercase">Reliable</h4>
                                <h6 class="text-capitalize font-weight-lighter">Balistic Tough 1680 Denier</h6>
                            </div>
                        </li>

                        <li class="d-flex align-items-center justify-content-start p-1">
                            <img src="./assets/img/dog-beds/icon-waterproof.svg" alt="Waterproof" />
                            <div class="d-flex flex-column align-items-start justify-content-start">
                                <h4 class="text-uppercase">Waterproof</h4>
                                <h6 class="text-capitalize font-weight-lighter ">Perfect for messy doggos</h6>
                            </div>
                        </li>

                        <li class="d-flex align-items-center justify-content-start p-1">
                            <img src="./assets/img/dog-beds/icon-skid-resistant.svg" alt="Resistant" />
                            <div class="d-flex flex-column align-items-start justify-content-start">
                                <h4 class="text-uppercase">Skid resistant bottom</h4>
                                <h6 class="text-capitalize font-weight-lighter">No Sliding, No Floor marks</h6>
                            </div>
                        </li>

                        <li class="d-flex align-items-center justify-content-start p-1">
                            <img src="./assets/img/dog-beds/icon-memory-foam.svg" alt="Memory" />
                            <div class="d-flex flex-column align-items-start justify-content-start">
                                <h4 class="text-uppercase">Memory Foam</h4>
                                <h6 class="text-capitalize font-weight-lighter">With poly orthopedic comfort</h6>
                            </div>
                        </li>

                        <li class="d-flex align-items-center justify-content-start p-1">
                            <img src="./assets/img/dog-beds/icon-easy-clean.svg" alt="Clean" />
                            <div class="d-flex flex-column align-items-start justify-content-start">
                                <h4 class="text-uppercase">Easy to clean</h4>
                                <h6 class="text-capitalize font-weight-lighter">With Removable base & bolster</h6>
                            </div>
                        </li>
                    </ul>
                </div><!-- END .wrapper -->
            </div><!-- END .col-md-5 -->

            <div class="col-md-3">

            </div><!-- END .col-md-3 -->

            <div class="col-md-4 text-center text-md-right">
                <a class="btn btn-lg btn-filled shadow" href="https://bullymakeshop.com/product-category/bullymake-beds/">Shop Now</a>
            </div><!-- END .col-md-4 -->
        </div><!-- END .row -->

        <img class="paws d-none d-md-block" src="./assets/img/dog-beds/Paw-prints.svg" alt="">

        <img class="d-md-none img-fluid mt-4" src="./assets/img/dog-beds/Features-Img-L.jpg" alt="">
    </div><!-- END .container -->
</section><!-- END #section-6 -->

<!-- Why Choose Bullymake -->
<app-whychoosebullymake></app-whychoosebullymake>

<!-- Sponsored In -->
<app-sponsored-in></app-sponsored-in>

<!-- Chewers Unite -->
<app-chewers-unite></app-chewers-unite>