import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-eu-dialog',
  templateUrl: './eu-dialog.component.html',
  styleUrls: ['./eu-dialog.component.css']
})
export class EuDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EuDialogComponent>) { }

  ngOnInit() {

  }

  close(data) {
    this.dialogRef.close(data);
  }
}
