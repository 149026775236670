import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        return next.handle(request).pipe(catchError(err => {

            // Verify `JWT-Token-Status` from the API, this will be always sent if the token is expired
            if( err.headers.get('JWT-Token-Status') && err.headers.get('JWT-Token-Status') === 'Expired' ) {

                // Remove Local Storage + Remove User Observable
                this.authService.logout();
            }
          
            // TODO: Check error Logs, the there is no 403 error code, Remove this code
            if(err.status === 403 ) {
                
              this.authService.logout();
            }
            
            if (err.status === 401) {
                
                // auto logout if 401 response returned from api
                this.authService.logout();
                this.router.navigate(['/signin']);
            }
       
            return throwError(err);
        }))
    }
}
