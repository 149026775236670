import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dog-gift',
  templateUrl: './dog-gift.component.html',
  styleUrls: ['./dog-gift.component.css']
})
export class DogGiftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
