import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-video-dialog',
	templateUrl: './video-dialog.component.html',
	styleUrls: ['./video-dialog.component.scss'],
	standalone: true,
})
export class VideoDialogComponent {
	constructor(
        public dialogRef: MatDialogRef<VideoDialogComponent>,

        @Inject(MAT_DIALOG_DATA) public data: {url:string}) {
    }
}
