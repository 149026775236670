import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsService, CartService } from '../../../../core/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelpVideoComponent } from '../../../../layout/dialogs/help-video/help-video.component';
import { Cart, Checkout, API_Response } from 'bullymake-types';
import { map } from 'rxjs/operators';


export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: 'app-customize',
	templateUrl: './customize.component.html',
	styleUrls: ['./customize.component.css']
})
export class CustomizeComponent implements OnInit {

	animal: string;
	name: string;
	fields;
	selection;

	cart;
	unit;

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		private dialog: MatDialog,
		private productsService: ProductsService,
		private cartService: CartService) {

		this.cart = {
			product_id: route.snapshot.data.id,
			quantity: 1,
			selection: this.productsService.getDefaultSelection({})
		};
	}

	ngOnInit() {

		this.fields = this.productsService.getFields();

		this.cart.selection.custom_box = true;
	}

	getFields() {

	}

	openDialog() {

		const dialogConfig = new MatDialogConfig();

		let dialogRef = this.dialog.open(HelpVideoComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(value => {

		});
	}

	updateUnit(value, selection) {

		if (value.checked == true) { selection.unit = 'kg'; }

		if (value.checked == false) { selection.unit = 'lbs'; }
	}

	updateCart(cart: Cart) {

		this.cartService.updateCart(cart)
			.pipe(
				map((res: API_Response<Checkout>): Checkout => {

					return res.data;
				}))
			.subscribe((data: Checkout) => {

				this.router.navigate(['/checkout']);
			});
	}
}
