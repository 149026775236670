import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { GoogleReviewResponse } from 'bullymake-types';

@Injectable({
  providedIn: 'root'
})
export class GoogleReviewsService {

  public readonly API_ROOT: string = environment.API_ROOT;

  constructor(private http: HttpClient) { }

  getReviews(pinned: boolean, page: number ):Observable<HttpResponse<GoogleReviewResponse>> {
    if (page === undefined) {
      page = 0;
    }
    return this.http.get<GoogleReviewResponse>( this.API_ROOT + 'api/v1/google-reviews?pinned=' + pinned +'&page='+ page, {observe: 'response'} );

  }
}
