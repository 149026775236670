import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beds',
  templateUrl: './beds.component.html',
  styleUrls: ['./beds.component.css']
})
export class BedsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
