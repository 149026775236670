<section id="section1" class="section-item">
  <div class="container">
    <h4 class="section-title text-dark text-uppercase mb-3 text-center">
      Personal Data Removal
    </h4>

    <div *ngIf="this.step===1" class="section-content mt-5">

      <h6 class="mt-4 font-secondary text-center">
        Please note by proceeding, this will delete all of your personal data. We will no longer be able to ship to you
        as we will no longer have your shipping address or any other record.
      </h6>

      <h6 class="mt-5 font-secondary text-center">
        Enter your email address to continue
      </h6>

      <div class="row">
        <div class="col-sm-3"></div>

        <div class="col-sm-6">
          <form [formGroup]="removalForm">
            <div class="form-group">
              <mat-form-field class="w-100">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" class=" text-center">
              </mat-form-field>
            </div><!-- END .form-group -->
            <button [disabled]="!removalForm.valid || loader" (click)="submitEmail()" type="submit" class="btn font-primary text-uppercase btn-block mb-2">SUBMIT</button>
          </form>
        </div>

        <div class="col-sm-3"></div>
      </div><!-- END .row -->
    </div>

    <div *ngIf="this.step===2" class="section-content mt-5">
      <h6 class="mt-4 font-secondary text-center">
        Thank you. Please check your inbox for a confirmation link to complete the process.
      </h6>
    </div>

    <div *ngIf="this.step===3" class="section-content mt-5">
      <h6 class="mt-4 font-secondary text-center">
        Thank you. Your personal data has been removed.
      </h6>
    </div>

  </div>
</section>
