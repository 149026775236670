import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BullymakeAngularLibraryComponent } from './bullymake-angular-library.component';
import { ButtonComponent } from './button/button.component';
import { ToyLabelPipe } from './pipes/toy-label/toy-label.pipe';
import { AddonLabelPipe } from './pipes/addon-label/addon-label.pipe';
import { RewardLabelPipe } from './pipes/reward-label/reward-label.pipe';
import { AllergiesLabelPipe } from './pipes/allergies-label/allergies-label.pipe';
import { WeightLabelPipe } from './pipes/weight-label/weight-label.pipe';



@NgModule({
  declarations: [
    BullymakeAngularLibraryComponent,
    ButtonComponent,
    ToyLabelPipe,
    AddonLabelPipe,
    RewardLabelPipe,
    AllergiesLabelPipe,
    WeightLabelPipe,
   
  ],
  imports: [
    CommonModule,

  ],
  exports: [
    BullymakeAngularLibraryComponent,
    ButtonComponent,
    ToyLabelPipe,
    AddonLabelPipe,
    RewardLabelPipe,
    AllergiesLabelPipe,
    WeightLabelPipe
  ]
})
export class BullymakeAngularLibraryModule { }
