import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-exit-intent',
  templateUrl: './exit-intent.component.html',
  styleUrls: ['./exit-intent.component.css']
})
export class ExitIntentComponent implements OnInit {

  subscribeForm: UntypedFormGroup;

  submitting = false;

  step = 1;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<ExitIntentComponent>,
      private formBuilder: UntypedFormBuilder,
	  private appService: AppService,
  ) { }

  ngOnInit() {
      this.subscribeForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]]
      });
  }

  close() {
    this.dialogRef.close();
  }

  submitEmail() {
    this.submitting = true;

    this.appService.exitIntent(this.subscribeForm.getRawValue()).subscribe(() => {
      this.step = 2;
    });
  }
}
