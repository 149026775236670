<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase text-orange mb-2 text-center">YOUR SEARCH FOR SUPER TOUGH DOG TOYS IS OVER - HERE’S WHY.</h3>

    <div class="section-content mt-0">
      <div class="cta-button text-center mb-4">
        <p class="lead text-success mb-4"><strong>GET A FREE BONUS TOY EVERY MONTH</strong></p>
        <a [routerLink]="['/product/1-month-subscription/promotion']" class="btn font-primary btn-join-now btn-bordered">GET OFFER NOW!</a>
      </div>

      <img class="img-fluid rounded mb-4 shadow" src="https://bullymake.com/wp-content/uploads/2021/05/jessie_and_tilly_173164941_364434258210243_1661876669381304739_n.jpg" alt="">
      <!-- <img class="img-fluid rounded mb-4 mt-4 shadow" src="/assets/img/bullymake-thanksgiving-a.jpg" alt=""> -->

      <!-- <h4 class="text-uppercase text-orange text-center  mt-5">ORDER BY DECEMBER 18 FOR DELIVERY BY CHRISTMAS!</h4> -->

      <p class="lead mt-3">BULLYMAKE poses a simple question. Why waste precious time and money buying dog toys that are too easily destroyed and <em>wrong</em> for your pet? You shouldn’t!</p>
      <p class="lead">BULLYMAKE has been around since 2014 with a special mission: keep power chewing dogs (and their parents) happy and satisfied. The way BULLYMAKE does this is by <em>designing and manufacturing unique</em>, highly durable toys every month catered specifically to your power chewer, <strong>right here in the USA.</strong></p>
      <p class="lead">BULLYMAKE specializes in Nylon, Ballistic, and Rubber toys that cater to chewing, tugging, fetching, and several other play tendencies. You also have the option of selecting a rope material. Take a quick look at the video for more about the toy options:</p>
      <div class="text-center mt-5">
        <iframe class="bullymake-video" width="560" height="315" src="https://www.youtube.com/embed/ba--WxJYe9o?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item bg-light">
  <div class="container h-100">
    <div class="section-content">
      <h3 class="text-uppercase text-orange text-center">THE GENIUS OF BULLYMAKE IS THAT ALL TOYS YOU RECEIVE ARE EXTREMELY TOUGH, CUSTOMIZED FOR YOUR PET, AND COME WITH A DURABILITY AND SATISFACTION GUARANTEE.</h3>
      <p class="lead mt-5">Each box is filled with 2-3 extremely durable toys created specifically for your dog’s play type. You can customize the materials of the toys you receive on a monthly basis. BULLYMAKE also sends 3 treats in every box that are all natural, healthy, and tie into the monthly theme. If your dog has severe food allergies, you can select a “Toys Only” option wherein the treats in the box are replaced with 2 additional toys.
      </p>
      <img class="img-fluid rounded shadow mt-5 mb-4" src="https://bullymake.com/wp-content/uploads/2019/01/dog-toy-photo3.jpg" alt="">
      <p class="lead">And if that wasn’t enough, BULLYMAKE manufactures their specialty Nylon, Rubber, and Ballistic products in the USA. All items are backed with a <strong>durability</strong> and <strong>satisfaction</strong> guarantee - nothing goes destroyed or it is replaced for free! Take advantage of the special offer below, and get a FREE bonus toy in each box for life! Limited time.</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section2 -->

<section id="section3" class="section-item text-center">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">Get a free bonus toy in every box for life! <br> Use Coupon <strong>FREETOY</strong> at checkout.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section5" class="section-item text-center bg-light">
  <div class="container">
    <div class="section-content">
      <h3 class="mt-3 mb-3 text-orange text-uppercase text-center">Watch this video for more information about BULLYMAKE.</h3>
      <iframe class="bg-dark m-auto d-block" width="100%" height="450" src="https://www.youtube.com/embed/UvY1a81QeJ4" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section5 -->

<section id="section6" class="section-item">
  <div class="container">
    <h2 class="section-title text-center text-uppercase">YOUR QUESTIONS - ANSWERED!</h2>
    <div class="section-content">
      <div class="row">
        <div class="col-sm-4">
          <div class="column-content">
            <accordion class="type-2" [closeOthers]="true">
              <accordion-group heading="What's in the box?">
                <p>Depending on your order, you will receive 2-3 toys and 3 full bags of grain free, USA made treats. We also have a "toys only" options where you will receive 4-5 toys instead of the treats.</p>
              </accordion-group>
              <accordion-group heading="When does it ship?">
                <p>We ship every single order the next Business day. All subsequent boxes are shipped 30 days after your first shipping date.</p>
              </accordion-group>
              <accordion-group heading="Where are your products made?">
                <p>Our toys and treats are all made in the USA except for our rope product (which is made in China). Rubber, nylon, and ballistic are all made in the USA. All of our treats are made in the USA.</p>
              </accordion-group>
              <accordion-group heading="What if my dog grows during the subscription?">
                <p>No problem! You can update your dog’s weight as they grow at any time in the “my account” area!</p>
              </accordion-group>
            </accordion>
          </div><!-- END .column-content -->
        </div><!-- END .col-sm-4 -->

        <div class="col-sm-4">
          <div class="column-content">
            <accordion class="type-2" [closeOthers]="true">
              <accordion-group heading="Is there a guarantee if something doesn’t work?">
                <p>You bet! We have an industry leading BULLYMAKE guarantee. If your dog destroys or is unsatisfied with any of our items, we replace them with completely different items for free.</p>
              </accordion-group>
              <accordion-group heading="Is a subscription required?">
                <p>A subscription is not required! All products are subscriptions by default but you may stop shipments at any time.</p>
              </accordion-group>
              <accordion-group heading="What are my customization options?">
                <p>Currently you may select from nylon, rubber, ballistic, or rope materials for your toys. This is done post-purchase. You can also choose from several allergy preferences.</p>
              </accordion-group>
              <accordion-group heading="Do you ever send Rawhides?">
                <p>NEVER! Too dangerous.</p>
              </accordion-group>
            </accordion>
          </div><!-- END .column-content -->
        </div><!-- END .col-sm-4 -->

        <div class="col-sm-4">
          <div class="column-content">
            <accordion class="type-2" [closeOthers]="true">
              <accordion-group heading="What if my dog has severe allergies?">
                <p>No problem! We have a "toys only" option where we replace all treats in the box with 2 additional toys, for a total of 4 toys.</p>
              </accordion-group>
              <accordion-group heading="How does it work / how do I cancel?">
                <p>Pick any of our plans (1, 3, 6, or 12 months). We'll ship your first box the next business day. Each plan automatically renews - and you can cancel anytime for the monthly one while the 3, 6 and 12 months will be under commitment period.</p>
              </accordion-group>
              <accordion-group heading="Can Australia & Canada receive treats?">
                <p>At this time, Canada and Australia cannot receive shipments with treats. We are working with both borders. Orders will automatically be switched to our "toys only" option which includes 4-5 toys per shipment!</p>
              </accordion-group>
              <!-- <accordion-group heading="What toy customizations can I receive?">
                <p>We'll let you select from Nylon, Rubber, Ballistic, or Rope materials. Please click "I'll do it" at the bottom of the page to get started.</p>
              </accordion-group> -->
              <accordion-group heading="Who delivers your boxes?">
                <p>We make the final delivery via USPS, Canada post or Australian Post depending on your country!</p>
              </accordion-group>
            </accordion>
          </div><!-- END .column-content -->
        </div><!-- END .col-sm-4 -->
      </div><!-- END .row -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section6 -->

<section id="section4" class="section-item bg-light pb-0">
  <div class="container">
    <h2 class="section-title text-dark text-uppercase mb-3 text-center">And check out all of their awesome reviews across the web:</h2>
    <div class="section-content text-center mt-5">
      <img class="bordered shadow img-fluid" src="assets/img/greviews.jpg" alt="">
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->