import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../core/services';
import { LoaderState } from 'bullymake-types';

@Component({
  selector: 'app-checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrls: ['./checkout-header.component.css']
})
export class CheckoutHeaderComponent implements OnInit {

  public show;

  constructor(private loaderService: LoaderService) {

  }

  /**
   * Init Methods
   * @return null
   */
  ngOnInit() {

    this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }

}
