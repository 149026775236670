<div class="card  border-0 shadow shadow-lg mb-3 p-0 w-100 toy-wrapper animate__animated " [ngClass]="isFlipped ? 'animate__flipInY':''"  >

	<ng-container *ngIf="isFlipped">
		<div class="card-body bg-dark p-3 border-0 " >
			<div class="d-flex justify-content-end" style="height:10%">
				<button (click)="flip()" class="btn btn-transparent ">
					<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#C9C8C8" class="bi bi-x" viewBox="0 0 16 16">
						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
					</svg>
				</button>
			</div>
			<div class="d-flex flex-column justify-content-end mt-0" style="height: 80%;">
				<ng-content select="[flipText]"></ng-content>
			</div>

			<div style="height: 10%;">
				<a href="javascript:void(0);" (click)="flip()" class="px-0 text-uppercase pb-2 text-decoration-none see-less" >
					<small>See less - </small>
				</a>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!isFlipped">
		<div class="card-body p-0 border-0 toy-body position-relative "
			style="background-image: url('{{imgUrl}}');"
			[style.backgroundPositionY]="bgPositionY">
			<div class="d-flex justify-content-between align-items-start">
				<div class="toy-header p-4">
					<h4>{{toyTitle}}</h4>
					<h4 class="text-primary">{{toySubtitle}}</h4>
				</div>
				<div class="toy-durability text-center" *ngIf="durability>0">
					<h5 class="text-uppercase py-2 mb-0 px-4" style="background-color: #1F1F1F;color:#C9C9C9;">
						Durability
					</h5>
					<div style="background-color: #353A40;" class="py-2">
						<ng-container *ngFor="let i of getDurabilityStatusArr()">
							<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/one-icon.webp" alt="durability status"
								class="mb-2">
						</ng-container>

						<h5 class="text-uppercase text-wrap text-white">
							{{durabilityName}}
						</h5>
					</div>
				</div>
			</div>
		</div>
		<div class="position-absolute sample-toy text-white px-2 py-1" *ngIf="isSampleToy">
			<span>Sample Toy</span>
		</div>
		<a class="card-footer text-center bg-orange border-0 text-white py-4 text-decoration-none text-uppercase"
			style="cursor: pointer;"  (click)="flip()">
			<span style="font-size: 1.5rem;">Flip to see more+
			</span>
		</a>
	</ng-container>

</div>
