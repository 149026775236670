import { AfterViewInit, Component } from '@angular/core';

@Component({
	selector: 'app-offer-toast',
	templateUrl: './offer-toast.component.html',
	styleUrls: ['./offer-toast.component.css']
})
export class OfferToastComponent implements AfterViewInit {

	public hide: boolean = false;

	public hasInit: boolean = false;

	ngAfterViewInit(): void {
		this.hasInit = true;
	}

}
