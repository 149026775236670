import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataStoreService } from '../data-store/data-store.service';
import { Observable } from 'rxjs';
import { LoginResponse } from 'bullymake-types';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	/**
	 * API Root URL
	 */
	public readonly API_ROOT: string = environment.API_ROOT;

	/**
	 * Local Storage Key for User Object
	 */
	private tokenKey: string = environment.TOKEN_KEY;

	constructor(
		private http: HttpClient,
		private dataStore: DataStoreService) {

	}

	/**
	 * Get Token from Local Storage
	 * @returns string | null
	 */
	public getToken(): string | null {

		// Get User JSON from Local Storage
		const token = localStorage.getItem(this.tokenKey);

		// Return User Object or null
		return token ? token : null;
	}

	/**
	 * Saves Token to Local Storage
	 * @param token string
	 * @returns void
	 */
	public setToken(token: string): void {

		// Save User Object to Local Storage
		localStorage.setItem(this.tokenKey, token);
	}

	/**
	 * Remove Saved token from Local Storage
	 * @returns void
	 */
	public removeToken(): void {

		// Remove User Object from Local Storage
		localStorage.removeItem(this.tokenKey);
	}

	/**
	 * Authenticate user with username and password
	 * @param username string
	 * @param password string
	 * @returns Observable<LoginResponse>
	 */
	login(username: string, password: string): Observable<LoginResponse> {

		return this.http.post<LoginResponse>(this.API_ROOT + 'jwt-auth/v1/token', { username: username, password: password })
	}

	/**
	 * Removes Token from Local Storage and Customer from Data Store
	 * @returns void
	 */
	logout(): void {

		// Removes token from Local Storage
		this.removeToken();
  
		// Remove Customer from data store
		this.dataStore.customer$.next(null);
	  }
}
