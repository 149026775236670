<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase mb-3 text-center text-orange">HOW YOU CAN CURE YOUR DOG’S BOREDOM WITH AMAZING DOG TOYS</h3>

    <div class="section-content mt-5 lead">
      <img class="img-fluid rounded mb-4 shadow" src="/assets/img/dog-boredom-image.jpg" alt="">
      <p class="mt-5">Karyn Owens loves her Labrador, Duke, but she was worried about him being bored while she couldn’t pay attention to him.  So how did Karyn cure this problem?  The answer is simple - with specially designed chew, fetch, tug, and puzzle toys from BULLYMAKE.</p>
      <p>BULLYMAKE has been around since 2014 with a simple mission: keep dogs (and their parents) happy, satisfied, and fulfilled. The way BULLYMAKE does this is by shipping a subscription box built specifically for dogs who love to play, and need toys to help them do just that.</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item bg-light">
  <div class="container h-100">
    <h3 class="section-title text-orange text-uppercase mb-3 text-center">THE GENIUS OF BULLYMAKE IS THAT YOU CAN HAVE A SUBSCRIPTION BOX DELIVERED TO YOU EVERY MONTH.</h3>

    <div class="section-content mt-5 lead">
      <img class="img-fluid rounded mb-4 shadow" src="/assets/img/kong-lander-image-2.jpg" alt="">
      <p class="mt-5">Each box is filled with 2-3 amazing, highly durable toys curated specifically for your dog. You can even customize the materials of the toys you receive on a monthly basis. BULLYMAKE also sends 3 treats in every box that are healthy and tie into the monthly theme.</p>
      <p>And if that wasn’t enough, BULLYMAKE manufactures their specialty Nylon, Rubber, and Ballistic products in the USA. All items are backed with a durability and satisfaction guarantee - nothing goes destroyed or it is replaced for free!</p>
      <p>BULLYMAKE is serious about durability and quality, offering BULLYMAKE guarantee on all of its products. Did your chompin’ dog manage to chew apart one of their toys after a couple of days? BULLYMAKE will send your dog something better suited, immediately, and at no cost to you.</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section2 -->

<section id="section3" class="section-item text-center">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">Grab 40% off your first shipment! Click here <br> to get this special offer.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion-b']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section4" class="section-item bg-light pb-0">
  <div class="container">
    <h2 class="section-title text-dark text-uppercase mb-3 text-center">And check out all of their awesome reviews across the web:</h2>
    <div class="section-content text-center mt-5">
      <img class="bordered shadow img-fluid" src="assets/img/greviews.jpg" alt="">
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->