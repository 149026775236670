import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjNgFor } from './obj-ng-for.filter';
import { SelectedItem } from './selected-item.filter';
import { FormatBoxContentText } from './format-box-content-text.filter';



@NgModule({
  declarations: [ObjNgFor,SelectedItem,FormatBoxContentText],
  imports: [
    CommonModule
  ],
  exports:[
    ObjNgFor,
    SelectedItem,
    FormatBoxContentText
  ]
})
export class PipesModule { }
