<div class="card mb-5 ">
    <div #rating class="card-header headerLabel p-3 bg-transparent d-flex  align-items-center">
        <img [src]="avatar" class="avatar rounded-circle" [ngStyle]="{'width':'36px','height':'36px'}"
        (error)="setDefaultAvatar()" loading="lazy">
        <div class="ml-3 w-75">
            <h6 class="limitText">{{review.reviewer_display_name}}</h6>
            <div class="d-flex align-items-center " [ngStyle]="{'height':'13px'}">
                <img src="assets/img/{{review.rating}}-stars.png"   class=" my-auto h-100 ">
                <h6 class="text-muted my-auto h-100 ml-2" [ngStyle]="{'fontSize':'0.7rem'}">{{review.created_at}}</h6>
            </div>
        </div>
    </div>
    <div class="card-body d-flex commentBody">
        <h6 class="card-text text-muted" *ngIf="review.comment;else noComment;">
            {{review.comment.slice(0,maxTextLength)}}
            <a class="show-more"  *ngIf="maxCommentLength>MAX_COMMENT_LENGTH;"  (click)="toggleShowHide()">{{showHide}}</a>
        </h6>
        <ng-template #noComment>
            <h6 class="card-text text-muted" >
                No Comment Available
            </h6>
        </ng-template>
    </div>
</div>