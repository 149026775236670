import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_Response, Initialize } from 'bullymake-types';

@Injectable({
	providedIn: 'root'
})
export class AppService {

	public readonly API_ROOT: string = environment.API_ROOT;

	constructor(private http: HttpClient) { }

	/**
	 * Get Initialize Data
	 * @returns Observable<API_Response<Initialize>>
	 */
	public initialize(): Observable<API_Response<Initialize>> {

		// Return the API Response
		return this.http.get<API_Response<Initialize>>(this.API_ROOT + 'api/v1/initialize/');
	}

	public euCheck() {

		return this.http.get<any>(this.API_ROOT + 'api/v1/eu-check/');
	}

	public exitIntent(email: string) {

		return this.http.post<any>(this.API_ROOT + 'api/v1/exit-intent/', email);
	}

	/**
	 * Record Tagger Session
	 * @param transaction_ID string
	 * @returns Observable<API_Response<null>>
	 */
	public recordTaggerSession(transaction_ID: string): Observable<API_Response<null>> {

		return this.http.post<API_Response<null>>(this.API_ROOT + 'api/v1/record-tagger-session/', {transaction_ID: transaction_ID});
	}
}
