<section id="cu" class="section-item bg-light pt-0">
	<div class="container position-relative pt-3 pt-sm-4">
		<div class="section-header">
			<img class="position-absolute top-icon" src="./assets/brand/favicon.png" alt="Bullymake" height="100">
			<h2 class="text-uppercase fw-semibold">Chewers Unite</h2>
			<p>Show us your happy chewer <span class="text-primary">#bullymake</span></p>
		</div><!-- END .section-header -->
		<!-- <img src="./assets/img/instagram-feed.png" class="img-fluid mt-5 w-100" alt=""> -->

		<div id="instagramCarousel" class="mt-5 carousel-wrapper style-1">
			<carousel [showIndicators]="false" class="d-none d-md-block">
				<slide>
					<div class="row">
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-1.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-2.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-3.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-4.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
					</div><!-- END .row -->
				</slide>

				<slide>
					<div class="row">
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-5.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-6.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-7.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-1.jpg" alt="blockquote">
						</div><!-- END .col-3 -->
					</div><!-- END .row -->
				</slide>
			</carousel>

			<carousel [showIndicators]="false" class="d-md-none d-block">

				<slide>
					<img class="d-block w-100 shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-1.jpg" alt="blockquote">
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-2.jpg" alt="blockquote">
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-3.jpg" alt="blockquote">
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-4.jpg" alt="blockquote">
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-5.jpg" alt="blockquote">
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid" src="https://bullymake.com/wp-content/uploads/2021/12/intagram-slide-6.jpg" alt="blockquote">
				</slide>
			</carousel>
		</div><!-- END #testimonialCarousel -->

	</div><!-- END .container -->
</section><!-- END .section-item -->