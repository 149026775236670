<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase text-orange mb-3 text-center">YOUR SEARCH FOR SUPER TOUGH DOG TOYS IS OVER - HERE’S WHY.</h3>

    <div class="section-content mt-5">
      <img class="img-fluid rounded mb-4 shadow" src="https://bullymake.com/wp-content/uploads/2019/01/dog-toy-photo1.jpg" alt="">
      <p class="lead mt-5">BULLYMAKE poses a simple question.  Why waste precious time and money buying dog toys that are too easily destroyed and <em>wrong</em> for your pet?  You shouldn’t!</p>
      <p class="lead">BULLYMAKE has been around since 2014 with a special mission: keep power chewing dogs (and their parents) happy and satisfied. The way BULLYMAKE does this is by <em>designing and manufacturing unique</em>, highly durable toys every month catered specifically to your power chewer, <strong>right here in the USA.</strong></p>
      <p class="lead">BULLYMAKE specializes in Nylon, Ballistic, and Rubber toys that cater to chewing, tugging, fetching, and several other play tendencies. You also have the option of selecting a rope material. Take a quick look at the video for more about the toy options:</p>
      <div class="text-center mt-5">
        <iframe class="bullymake-video" width="560" height="315" src="https://www.youtube.com/embed/ba--WxJYe9o?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item bg-light">
  <div class="container h-100">
    <div class="section-content">
      <h3 class="text-uppercase text-orange text-center">THE GENIUS OF BULLYMAKE IS THAT ALL TOYS YOU RECEIVE ARE EXTREMELY TOUGH, CUSTOMIZED FOR YOUR PET, AND COME WITH A DURABILITY AND SATISFACTION GUARANTEE.</h3>
      <p class="lead mt-5">Each box is filled with 2-3 extremely durable toys created specifically for your dog’s play type. You can customize the materials of the toys you receive on a monthly basis. BULLYMAKE also sends 3 treats in every box that are all natural, healthy, and tie into the monthly theme. If your dog has severe food allergies, you can select a “Toys Only” option wherein the treats in the box are replaced with 2 additional toys.
      </p>
      <img class="img-fluid rounded shadow mt-5 mb-4" src="https://bullymake.com/wp-content/uploads/2019/01/dog-toy-photo3.jpg" alt="">
      <p class="lead">And if that wasn’t enough, BULLYMAKE manufactures their specialty Nylon, Rubber, and Ballistic products in the USA. All items are backed with a durability and satisfaction guarantee - nothing goes destroyed or it is replaced for free! Take advantage of this 20% off special offer to get your first box for just $31. Limited time only!</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section2 -->

<section id="section3" class="section-item text-center">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">Grab 20% off your first shipment! Click here <br> to get this special offer.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion-fb20']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section4" class="section-item bg-light pb-0">
  <div class="container">
    <h2 class="section-title text-dark text-uppercase mb-3 text-center">And check out all of their awesome reviews across the web:</h2>
    <div class="section-content text-center mt-5">
      <img class="bordered shadow img-fluid" src="assets/img/greviews.jpg" alt="">
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->
