<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase text-orange mb-3 text-center">HOW THIS DOG COMPANY CHANGED THE GAME FOR POWER CHEWING DOGS</h3>

    <div class="section-content mt-5">
      <img class="img-fluid rounded mb-4 shadow" src="https://bullymake.com/wp-content/uploads/2018/05/bayoubloodkennels_30830250_1340733952694640_8777060557535576064_n-1.jpg" alt="">
      <p class="lead mt-5">BULLYMAKE poses a simple question.  Why waste precious time and money buying dog toys that are too easily destroyed and wrong for your pet?  You shouldn’t!</p>
      <p class="lead">BULLYMAKE has been around since 2014 with a special mission: keep power chewing dogs (and their parents) happy and satisfied. The way BULLYMAKE does this is by designing and manufacturing unique, highly durable toys every month catered specifically to your power chewer, right here in the USA, and shipped right to your door.</p>
      <p class="lead">BULLYMAKE specializes in Nylon, Ballistic, and Rubber toys that cater to chewing, tugging, fetching, and several other play tendencies. You also have the option of selecting a rope material. Take a quick look at the video for more about the toy options:</p>
      <div class="text-center mt-5">
        <iframe class="bullymake-video" width="560" height="315" src="https://www.youtube.com/embed/ba--WxJYe9o?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item bg-light">
  <div class="container h-100">
    <div class="section-content">
      <h3 class="text-uppercase text-orange text-center">THE GENIUS OF BULLYMAKE IS THAT ALL TOYS YOU RECEIVE ARE EXTREMELY TOUGH, CUSTOMIZED FOR YOUR PET, AND COME WITH A DURABILITY AND SATISFACTION GUARANTEE.</h3>
      <p class="lead mt-5">Each box is filled with 2-3 extremely durable toys created specifically for your dog’s play type. You can customize the materials of the toys you receive on a monthly basis. BULLYMAKE also sends 3 treats in every box that are all natural, healthy, and tie into the monthly theme. If your dog has severe food allergies, you can select a “Toys Only” option wherein the treats in the box are replaced with 2 additional toys.</p>
      <p class="lead">And if that wasn’t enough, BULLYMAKE manufactures their specialty Nylon, Rubber, and Ballistic products in the USA. All items are backed with a durability and satisfaction guarantee, so never fear again about dealing with items that aren’t suited for your dog. Take advantage of the special offer below, and get a FREE bonus toy in each box for life! Limited time.</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section2 -->

<section id="section3" class="section-item text-center">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">Grab 40% off your first shipment! Click here <br> to get this special offer.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion-b']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section4" class="section-item bg-light pb-0">
  <div class="container">
    <h2 class="section-title text-dark text-uppercase mb-3 text-center">And check out all of their awesome reviews across the web:</h2>
    <div class="section-content text-center mt-5">
      <img class="bordered shadow img-fluid" src="assets/img/greviews.jpg" alt="">
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->

<!-- Use of this pixel is subject to the Amazon ad specs and policies at http://www.amazon.com/b/?&node=7253015011 -->
<script type='text/javascript'>
    var _pix = document.getElementById('_pix_id_d0e1be67-ae2e-9a29-c2b0-888c38fa2e6c');
    if (!_pix) {
        var protocol = '//';
        var a = Math.random() * 1000000000000000000;
        _pix = document.createElement('iframe');
        _pix.style.display = 'none';
        _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Dd0e1be67-ae2e-9a29-c2b0-888c38fa2e6c%26type%3D49%26m%3D1&ex-fch=416613&ex-src=https://bullymake.com&ex-hargs=v%3D1.0%3Bc%3D3652507800501%3Bp%3DD0E1BE67-AE2E-9A29-C2B0-888C38FA2E6C' + '&cb=' + a);
        _pix.setAttribute('id', '_pix_id_d0e1be67-ae2e-9a29-c2b0-888c38fa2e6c');
        document.body.appendChild(_pix);
    }
</script>
<noscript> <img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Dd0e1be67-ae2e-9a29-c2b0-888c38fa2e6c%26type%3D49%26m%3D1&ex-fch=416613&ex-src=https://bullymake.com&ex-hargs=v%3D1.0%3Bc%3D3652507800501%3Bp%3DD0E1BE67-AE2E-9A29-C2B0-888C38FA2E6C' /></noscript>
