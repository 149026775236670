import { Component, OnInit } from '@angular/core';
import { VersionAComponent } from './version-a/version-a.component';
import { VersionBComponent } from './version-b/version-b.component';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	standalone: true,
	imports: [
		VersionAComponent,
		VersionBComponent
	]
})
export class HomeComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {

	}
}
