<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase text-orange mb-3 text-center">SEE WHAT OTHERS ARE SAYING ABOUT BULLYMAKE</h3>

    <div class="text-center mt-5">
      <!-- <a href="https://g.page/r/Cc3UCXSnYqrIEAg/review" target="_blank"> -->
        <img src="assets/img/reviews.png" alt="">
      <!-- </a> -->
    </div>
    <!-- bullymake-ng-src/src/app/reviews/partials -->
    <div class="section-content mt-5">

      <div class="row google-reviews" id="reviews-page">
        <div *ngFor="let review of reviews" class="col-12 col-md-6 col-lg-4  quotes ">
          <app-google-review [review]="review"></app-google-review>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <button class="btn btn-filled btn-lg btn-orange" *ngIf="loadMore" [disabled]="loading" (click)="loadMoreReviews()">Load More</button>
      </div>

    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<!-- Use of this pixel is subject to the Amazon ad specs and policies at http://www.amazon.com/b/?&node=7253015011 -->
<script type='text/javascript'>
    var _pix = document.getElementById('_pix_id_32f97a88-fd57-fbe7-1c08-4de3f91bf6ac');
    if (!_pix) {
        var protocol = '//';
        var a = Math.random() * 1000000000000000000;
        _pix = document.createElement('iframe');
        _pix.style.display = 'none';
        _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D32f97a88-fd57-fbe7-1c08-4de3f91bf6ac%26type%3D47%26m%3D1&ex-fch=416613&ex-src=https://bullymake.com&ex-hargs=v%3D1.0%3Bc%3D3652507800501%3Bp%3D32F97A88-FD57-FBE7-1C08-4DE3F91BF6AC' + '&cb=' + a);
        _pix.setAttribute('id', '_pix_id_32f97a88-fd57-fbe7-1c08-4de3f91bf6ac');
        document.body.appendChild(_pix);
    }
</script>
<noscript> <img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D32f97a88-fd57-fbe7-1c08-4de3f91bf6ac%26type%3D47%26m%3D1&ex-fch=416613&ex-src=https://bullymake.com&ex-hargs=v%3D1.0%3Bc%3D3652507800501%3Bp%3D32F97A88-FD57-FBE7-1C08-4DE3F91BF6AC' /></noscript>
