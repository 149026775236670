import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { BullymakeBlogService } from '../../bullymake-blog.service';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.css']
})
export class SinglePostComponent implements OnInit {

  slug;

  post;

  constructor(private route: ActivatedRoute, private service: BullymakeBlogService, public sanitizer: DomSanitizer, private router: Router) { }

  ngOnInit() {

    this.route.params
      .subscribe( params => {
        if(params.slug) this.slug = params.slug;
        this.getPost(this.slug);
      });
  }

  getPost(slug) {
    this.service.getPost(slug)
      .subscribe(data => {
        
        if( !data ) { this.router.navigate(['/not-found']); return; }
        this.post = data;
      });
  }

  sanitize(html) {

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
