import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxStripeModule } from 'ngx-stripe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { UnsubscribeComponent } from './modules-page/main/unsubscribe/unsubscribe.component';
import { RemovalComponent } from './modules-page/main/removal/removal.component';
import { BullymakeBlogComponent } from './modules-page/main/bullymake-blog/bullymake-blog.component';

import { SingleProductComponent } from './modules-page/main/single-product/single-product.component';
import { CustomizeComponent } from './modules-page/main/single-product/customize/customize.component';
import { ReviewsComponent } from './modules-page/main/reviews/reviews.component';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { NotificationInterceptor } from './core/interceptors/notification.interceptor';
// import { FormatBoxContentText } from './pipes/format-box-content-text.filter';
import { UpsellDialogComponent } from './layout/dialogs/upsell-dialog/upsell-dialog.component';
import { HelpVideoComponent } from './layout/dialogs/help-video/help-video.component';
import { SinglePostComponent } from './modules-page/main/bullymake-blog/partials/single-post/single-post.component';
import { EuDialogComponent } from './layout/dialogs/eu-dialog/eu-dialog.component';
import { ExitIntentComponent } from './layout/dialogs/exit-intent/exit-intent.component';
import { CompareComponent } from './modules-page/main/landing/compare/compare.component';
import { DogBoredomComponent } from './modules-page/main/landing/dog-boredom/dog-boredom.component';
import { DogGiftComponent } from './modules-page/main/landing/dog-gift/dog-gift.component';
import { DogToyPComponent } from './modules-page/main/landing/dog-toy-p/dog-toy-p.component';
import { DogToyP2Component } from './modules-page/main/landing/dog-toy-p2/dog-toy-p2.component';
import { WhyBullymakeAComponent } from './modules-page/main/why-bullymake/why-bullymake-a/why-bullymake-a.component';
import { WhyBullymakeBComponent } from './modules-page/main/why-bullymake/why-bullymake-b/why-bullymake-b.component';
import { WhyBullymakeCComponent } from './modules-page/main/why-bullymake/why-bullymake-c/why-bullymake-c.component';
import { DogToyAComponent } from './modules-page/main/dog-toy/dog-toy-a/dog-toy-a.component';
import { DogToyBComponent } from './modules-page/main/dog-toy/dog-toy-b/dog-toy-b.component';
import { DogToyB2Component } from './modules-page/main/dog-toy/dog-toy-b2/dog-toy-b2.component';
import { DogToyCComponent } from './modules-page/main/dog-toy/dog-toy-c/dog-toy-c.component';
import { SubscriptionBoxAComponent } from './modules-page/main/subscription-box/subscription-box-a/subscription-box-a.component';
import { SubscriptionBoxBComponent } from './modules-page/main/subscription-box/subscription-box-b/subscription-box-b.component';
import { SubscriptionBoxB2Component } from './modules-page/main/subscription-box/subscription-box-b2/subscription-box-b2.component';
import { SubscriptionBoxCComponent } from './modules-page/main/subscription-box/subscription-box-c/subscription-box-c.component';
import { DogToyB20Component } from './modules-page/main/dog-toy/dog-toy-b20/dog-toy-b20.component';
import { SubscriptionBoxB20Component } from './modules-page/main/subscription-box/subscription-box-b20/subscription-box-b20.component';
import { CheckoutHeaderComponent } from './layout/checkout-header/checkout-header.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PromotionsComponent } from './modules-page/main/promotions/promotions.component';
import { LoginComponent } from './modules-page/main/login/login.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GoogleReviewComponent } from './modules-page/main/reviews/partials/google-review/google-review.component';
import { PipesModule } from './core/pipes/pipes.module';
import { SharedModule } from './modules-core/shared/shared.module';
import { ChewersUniteComponent } from './modules-page/main/beds/partials/chewers-unite/chewers-unite.component';
import { WhychoosebullymakeComponent } from './modules-page/main/beds/partials/whychoosebullymake/whychoosebullymake.component';
import { SponsoredInComponent } from './modules-page/main/beds/partials/sponsored-in/sponsored-in.component';
import { BedsComponent } from './modules-page/main/beds/beds.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BullymakeAngularLibraryModule } from 'bullymake-angular-library';
import { BullymakeErrorHandler } from './core/error-handler/bullymake-error-handler';
import { NgOptimizedImage } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OfferToastComponent } from './features/offer-toast/offer-toast.component';

/**
 * TODO:
 * - User/Customer should be set to observable on login
 * - Disbale updateCart on addon componnent, as it is already called on checkout
 */
@NgModule({
    imports: [
    BrowserModule,
        HttpClientModule,
        AccordionModule.forRoot(),
        NgxStripeModule.forRoot('pk_test_M76WUOrEz93Ge81osYIa5EtI'),
        CarouselModule,
        BsDropdownModule.forRoot(),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatDialogModule,
        MatCheckboxModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatRadioModule,
        MatAutocompleteModule,
        SharedModule,
        routing,
        PipesModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMatSelectSearchModule,
        BullymakeAngularLibraryModule,
        NgOptimizedImage,
		MatIconModule,
		NgxSkeletonLoaderModule
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        UnsubscribeComponent,
        RemovalComponent,
        BullymakeBlogComponent,
        SingleProductComponent,
        CustomizeComponent,
        UpsellDialogComponent,
        HelpVideoComponent,
        SinglePostComponent,
        ReviewsComponent,
        EuDialogComponent,
        ExitIntentComponent,
        CompareComponent,
        DogBoredomComponent,
        DogGiftComponent,
        DogToyPComponent,
        DogToyP2Component,
        WhyBullymakeAComponent,
        WhyBullymakeBComponent,
        WhyBullymakeCComponent,
        DogToyAComponent,
        DogToyBComponent,
        DogToyB2Component,
        DogToyCComponent,
        SubscriptionBoxAComponent,
        SubscriptionBoxBComponent,
        SubscriptionBoxB2Component,
        SubscriptionBoxCComponent,
        DogToyB20Component,
        SubscriptionBoxB20Component,
        CheckoutHeaderComponent,
        PromotionsComponent,
        LoginComponent,
        GoogleReviewComponent,
        ChewersUniteComponent,
        WhychoosebullymakeComponent,
        SponsoredInComponent,
        BedsComponent,
        OfferToastComponent
    ],
    exports: [
        AccordionModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        {
            provide: ErrorHandler,
            useClass: BullymakeErrorHandler
        },
        MatDatepickerModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
