<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase text-orange mb-3 text-center">WHY BULLYMAKE IS THE BEST SUBSCRIPTION BOX THAT MONEY CAN BUY.</h3>

    <div class="section-content mt-5">
      <img class="img-fluid rounded mb-4 shadow" src="https://bullymake.com/wp-content/uploads/2019/01/subscription-box-photo1.jpg" alt="">
      <p class="lead mt-5">Karyn Owens loves her Pittie, Duke, but frankly, she was getting a little sick of him destroying all of his toys after five minutes of chewing and play time.</p>
      <p class="lead">Too many trips to the store and too many poor quality purchases online, and what happened to Karyn? She lost time, she lost money – but most importantly, she knows that Duke still isn’t getting the correct toys he deserves!</p>
      <p class="lead">BULLYMAKE has been around since 2014 with a special mission: keep power chewing dogs (and their parents) happy and satisfied. The way BULLYMAKE does this is by designing and manufacturing unique, highly durable toys every month catered specifically to your power chewer, right here in the USA, and shipped right to you.</p>
      <!-- <p class="lead">In comparison, other subscription box companies don't specifically cater or create products for these doggos that BULLYMAKE diverts all their attention to.</p> -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item bg-light">
  <div class="container h-100">
    <div class="section-content mt-3">
      <img class="img-fluid rounded shadow" src="https://bullymake.com/wp-content/uploads/2019/01/subscription-box-photo2.jpg" alt="">
      <h3 class="text-uppercase text-orange mt-5">THE GENIUS OF BULLYMAKE IS THAT YOU CAN HAVE A BOX DELIVERED TO YOU EVERY MONTH WITH THE EXACT TOYS YOUR DOG NEEDS.</h3>
      <p class="lead mt-5">Each box is filled with 2-3 amazing, highly durable toys curated specifically for your dog. You can even customize the materials of the toys you receive on a monthly basis. BULLYMAKE also sends 3 treats in every box that are all natural, healthy, and tie into the monthly theme. If your dog has severe food allergies, you can select a “Toys Only” option wherein the treats in the box are replaced with 2 additional toys.</p>
      <p class="lead">And if that wasn’t enough, BULLYMAKE designs and makes most of their products in the USA. All items are backed with a <strong>durability</strong> and <strong>satisfaction</strong> guarantee - nothing goes destroyed or it is replaced for free! Take advantage of our 40% off special offer to get your first box for just $23. Limited time only!</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section2 -->

<section id="section3" class="section-item text-center">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">Subscribe NOW and get 20% off your first box! <br> Instantly applied when you click here.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion-c']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section4" class="section-item bg-light pb-0">
  <div class="container">
    <h2 class="section-title text-dark text-uppercase mb-3 text-center">And check out all of their awesome reviews across the web:</h2>
    <div class="section-content text-center mt-5">
      <img class="bordered shadow img-fluid" src="assets/img/greviews.jpg" alt="">
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->
